import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import crypto from 'crypto';
import cookie from 'react-cookies';
import API from '../../Api';

import Header from '../Header';
import Sidebar from '../Sidebar';

let baseURL = 'https://vidzon.node.pixelnx.com';

class EditVideo extends Component {
    constructor(props) {
        super(props);
        this.state = { 
            campaignid : '',
            editvideo : '',
            vidstatus : ''
        }

        this.playVideo = this.playVideo.bind(this);
    }
    componentDidMount(){
        let campid = this.props.match.params.campid;
        const uid = this.decrypt(cookie.load('accesstoken'));
        document.body.classList.add('open-loader');
        if(campid){
            API.get('api/list_campaign/'+ uid +'/'+ campid)
            .then(res => {
                if(res.data.status === 'success'){
                    if(res.data.data.length === 0){
                        this.props.history.push('/create-campaign');
                    }else{
                        let vstatus = res.data.data[0].video_status;
                        this.setState({ vidstatus : vstatus });
                        if(vstatus === 'FINISH'){
                            if(res.data.data[0].edited_video_url){
                                let vidURL = baseURL + '/aws_videos/'+ campid + '/' + res.data.data[0].edited_video_url;
                                this.setState({ editvideo : vidURL });
                            }else{
                                let vidURL = baseURL + '/aws_videos/'+ campid + '/' + res.data.data[0].compile_video_url;
                                this.setState({ editvideo : vidURL });
                            }
                        }else{
                            this.props.history.push('/get-video/' + campid);    
                        }
                        document.body.classList.remove('open-loader');
                    }
                    
                }
            });
        }
    }
    decrypt(text){
        var decipher = crypto.createDecipher('aes-256-cbc','vidzon@#$%^&*()!')
        var dec = decipher.update(text,'hex','utf8')
        dec += decipher.final('utf8');
        return dec;
    }
    playVideo(){
        this.refs.video.play();
        this.refs.videoplay.classList.add('videoplaying');
    }
    render() { 
        return ( 
            <div className="vd_main_afterlogin">
                <Header />
                <Sidebar />
                <div className="vd_create_camp_progress">
                    <h1>Edit Video</h1>
                    <ul>
                        <li>
                            <Link to={'/create-campaign/' + this.props.match.params.campid}></Link>
                            <span className="vd_cp_point"></span>
                            <span className="vd_cp_title">Create New Campaign</span>
                        </li>
                        <li className="active">
                            <Link to={'/get-video/' + this.props.match.params.campid}></Link>
                            <span className="vd_cp_point"></span>
                            <span className="vd_cp_title">Get Video</span>
                        </li>
                        <li className="active">
                            <Link to={ this.state.vidstatus === "FINISH" ? '/edit-video/' + this.props.match.params.campid : '/get-video/' + this.props.match.params.campid}></Link>
                            <span className="vd_cp_point"></span>
                            <span className="vd_cp_title">Edit Video</span>
                        </li>
                        <li>
                            <Link to={'/create-blog/' + this.props.match.params.campid}></Link>
                            <span className="vd_cp_point"></span>
                            <span className="vd_cp_title">Create Blog</span>
                        </li>
                        <li>
                            <Link to={'/publish-on-youtube/' + this.props.match.params.campid}></Link>
                            <span className="vd_cp_point"></span>
                            <span className="vd_cp_point p2"></span>
                            <span className="vd_cp_title">Publish On YouTube</span>
                        </li>
                    </ul>
                </div>

                <div className="vd_campBox_wrapper">
                    <div className="vd_video_wrapper" ref="videoplay">
                        <video ref="video" width="320" height="240" src={this.state.editvideo}></video>
                        <div className="vd_video_overlay" onClick={this.playVideo}>
                            <span className="vd_video_play_btn">
                                <img src={ process.env.PUBLIC_URL + '/assets/images/icons/play.svg' } alt="" />
                            </span>
                        </div>
                    </div>
                    <div className="text-center">
                        <Link to={'/editor/' + this.props.match.params.campid } className="vd_btn">Edit Video</Link>&nbsp;&nbsp;&nbsp;
                        <Link to={'/create-blog/' + this.props.match.params.campid } className="vd_btn">Continue</Link>
                    </div>
                </div>
                
            </div>
        );
    }
}
 
export default EditVideo;