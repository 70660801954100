import React, { Component } from 'react';
import API from '../Api';
import { Link } from 'react-router-dom';
import validator from 'validator';
import crypto from 'crypto';
import cookie from 'react-cookies';

import AlertMessage from '../AlertMessage';

import Header from './Header';
import Sidebar from './Sidebar';

const base_url = "https://vidzon.node.pixelnx.com/";

const WordpressListNoData = (props) => {
    let d = props.listcount;
    if(d === 0){
        return <tr><td colSpan="6" style={{textAlign:"center"}}>There is no wordpress created yet.</td></tr>;
    }else{
        return null;
    }
}

class SettingWordpress extends Component {
    constructor(props) {
        super(props);
        this.state = {
            blogname : '', 
            blogurl : '',
            bloguser : '',
            blogpass : '',
            editblogid : '',
            
            alertclass : '',
            alertmessage : '', 

            wplist : []
        };

        this.handleChange = this.handleChange.bind(this);
        this.createWordpress = this.createWordpress.bind(this);
    }

    componentDidMount(){
        let uid = this.decrypt(cookie.load('accesstoken')); 
        fetch(base_url + 'api/list_wpblog/' + uid + '/0')
        .then(res => res.json())
        .then(wplist =>{
            this.setState({wplist : wplist.data});
        });
    }
    decrypt(text){
        var decipher = crypto.createDecipher('aes-256-cbc','vidzon@#$%^&*()!')
        var dec = decipher.update(text,'hex','utf8')
        dec += decipher.final('utf8');
        return dec;
    }
    handleChange(e){
        this.setState({[e.target.name] : e.target.value });
    }
    validateForm(){
        const emptybname = validator.isEmpty(this.state.blogname, {ignore_whitespace:true});
        const emptyburl = validator.isEmpty(this.state.blogurl, {ignore_whitespace:true});
        const emptybuser = validator.isEmpty(this.state.bloguser, {ignore_whitespace:true});
        const emptybpass = validator.isEmpty(this.state.blogpass, {ignore_whitespace:true});
        
        const isburl = validator.isURL(this.state.blogurl, {protocols: ['http','https'], require_protocol: true});

        if(emptybname || emptyburl || emptybuser || emptybpass){
            this.setState({ alertclass : 'error', alertmessage : 'Field can not be empty!' });
            return false;
        }
        
        if(!isburl){
            this.setState({ alertclass : 'error', alertmessage : 'Please enter valid URL. Also use HTTP/HTTPS' });
            return false;
        }
        
        return true;
        
    }
    createWordpress(e){
        e.preventDefault();
        const isValid = this.validateForm();
        if(isValid){
            document.body.classList.add('open-loader');
            if(this.decrypt(cookie.load('accesstoken'))){
                //post signin data to the server
                API.post('/api/add_edit_wpblog', {
                    id : this.state.editblogid || 0,
                    user_id : this.decrypt(cookie.load('accesstoken')),
                    name : this.state.blogname,
                    url : this.state.blogurl,
                    username : this.state.bloguser,
                    password : this.state.blogpass,
                })
                .then(res => {
                    if(res.data.message === '' || res.data.message === null || res.data.message === undefined ){
                    this.setState({ alertclass : 'error', alertmessage : 'Something went wrong, please check your wordpress detail.' });
                    }else if(res.data.status === 'success'){
                        this.setState({ blogname: '', blogurl : '', bloguser : '', blogpass : '' });
                        this.setState({ alertclass : 'success', alertmessage : res.data.message });
                        window.location.reload();
                    }else{
                        if(res.data.message.faultString){
                            this.setState({ alertclass : 'error', alertmessage : res.data.message.faultString });
                        }else{
                            this.setState({ alertclass : 'error', alertmessage : res.data.message });
                        }
                    }  
                    document.body.classList.remove('open-loader'); 
                });
            }else{
                this.setState({ alertclass : 'error', alertmessage : 'Something went wrong, please Login again.' });
            }
            
            setTimeout(() => {
                this.setState({alertclass : ''});
                this.setState({alertmessage : ''});
            }, 3000);
        }else{
            setTimeout(() => {
                this.setState({alertclass : ''});
                this.setState({alertmessage : ''});
            }, 3000);
        }
    }

    editWordpress(wpid){
        let uid = this.decrypt(cookie.load('accesstoken')); 
        fetch(base_url + 'api/list_wpblog/' + uid + '/' + wpid)
        .then(res => res.json())
        .then(wpdetail =>{
            this.setState({
                blogname : wpdetail.data[0].name,
                blogurl : wpdetail.data[0].url,
                bloguser : wpdetail.data[0].username,
                blogpass : wpdetail.data[0].password,
                editblogid : wpid
            })
        })
    }

    deleteWordpress(wpid){
        const r = window.confirm("Do you really want to Delete?"); 
        if(r === true){ 
            let uid = this.decrypt(cookie.load('accesstoken')); 
            fetch(base_url + 'api/delete_wpblog/' + uid + '/' + wpid)
            .then(res => res.json())
            .then(wpdetail =>{
                if(wpdetail.status === 'success'){
                    this.setState({ alertclass : 'success', alertmessage : wpdetail.message });
                    setTimeout(() => {
                        this.setState(this.state.wplist);
                        this.props.history.push("/settings/wordpress");
                        window.location.reload();
                    }, 1000);
                }else{
                    this.setState({ alertclass : 'error', alertmessage : wpdetail.message });
                }
            })
        }  
    }

    

    render() { 
        
        return ( 
            <div className="vd_main_afterlogin">
                <Header />
                <Sidebar />
                <div className="vd_settingbox_wrapper">
                    <div className="vd_setting_tab_menu">
                        <ul>
                            {/* <li><Link to="/settings/youtube">Youtube</Link></li> */}
                            <li className="active"><Link to="/settings/wordpress">WordPress</Link></li>
                            <li><Link to="/settings/amazon">Amazon</Link></li>
                        </ul>
                    </div>
                    <div className="vd_setting_content">
                        <p><strong>Step 1 -</strong> Download the Vidzon WordPress Plugin</p>
                        <div className="vd_note"><strong>Note :</strong> First you have to download the Vidzon WordPress Plugin and install it on destination WordPress. Then you can save the bellow settings.</div><br/>
                        <div className="text-center">
                            <a href={process.env.PUBLIC_URL + '/vidzon-connect.zip'} download target="_blank" rel="noopener noreferrer" className="vd_btn vd_btn_green">Download Plugin</a><br/><br/><br/>
                        </div>
                        <p><strong>Step 2 -</strong> Add your WordPress site bellow.</p>
                        <form onSubmit={this.createWordpress}>
                            <div className="vd_input_inline">
                                <div className="vd_input_wrapper">
                                    <input type="text" className="vd_input" placeholder="Blog Name" name="blogname" value={this.state.blogname} onChange={this.handleChange} />
                                </div>
                                <div className="vd_input_wrapper">
                                    <input type="text" className="vd_input" placeholder="Your URL" name="blogurl" value={this.state.blogurl} onChange={this.handleChange} />
                                </div>
                            </div>
                            <div className="vd_input_inline">
                                <div className="vd_input_wrapper">
                                    <input type="text" className="vd_input" placeholder="User Name" name="bloguser" value={this.state.bloguser} onChange={this.handleChange} />
                                </div>
                                <div className="vd_input_wrapper">
                                    <input type="password" className="vd_input" placeholder="Password" name="blogpass" value={this.state.blogpass} onChange={this.handleChange} />
                                </div>
                                <input type="hidden" name="editblogid" value={this.state.editblogid} />
                            </div>
                            <br/>
                            <div className="text-center">
                                <button type="submit" className="vd_btn">Save</button> 
                            </div>
                        </form>
                        <br/><br/><br/>
                        <div className="vd_table_wrapper">
                            <table>
                                <thead>
                                    <tr>
                                        <th>#</th>
                                        <th>Name</th>
                                        <th>URL</th>
                                        <th>User</th>
                                        <th>Password</th>
                                        <th>Action</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <WordpressListNoData listcount={this.state.wplist.length} />

                                    {this.state.wplist.map((wp, index) =>
                                        <tr key={wp.id}>
                                            <td>{index + 1}</td>
                                            <td>{wp.name}</td>
                                            <td>
                                                <a target="_blank" rel="noopener noreferrer" href={wp.url}>{wp.url}</a>
                                            </td>
                                            <td>{wp.username}</td>
                                            <td>****************</td>
                                            <td>
                                                <span className="vd_table_btn edit" onClick={ () => this.editWordpress(wp.id) }>Edit</span>
                                                <span className="vd_table_btn delete" onClick= { () => this.deleteWordpress(wp.id) }>Delete</span>
                                            </td>
                                        </tr>
                                    )}
                                </tbody>
                            </table>
                        </div>

                    </div>
                </div>

                <AlertMessage alertcls={this.state.alertclass} alertmsg = {this.state.alertmessage} />
            </div>
        );
    }
}
 
export default SettingWordpress;