import React, { Component } from 'react';
import API from '../../Api';
import { Link } from 'react-router-dom';
import validator from 'validator';
import crypto from 'crypto';
import cookie from 'react-cookies';

import AlertMessage from '../../AlertMessage';

import Header from '../Header';
import Sidebar from '../Sidebar';


class CreateCampaign extends Component {
    constructor(props) {
        super(props);
        this.state = { 
            campname : '',
            editcampid : '',
            alertclass : '',
            alertmessage : ''
        }

        this.handleChange = this.handleChange.bind(this);
        this.createCampaign = this.createCampaign.bind(this);
    }
    componentDidMount(){
        document.body.classList.remove('open-loader');
        let campid = this.props.match.params.campid;
        const uid = this.decrypt(cookie.load('accesstoken'));
        if(campid){
            API.get('api/list_campaign/'+ uid +'/'+ campid)
            .then(res => {
                if(res.data.status === 'success'){
                    if(res.data.data.length === 0){
                        this.props.history.push('/create-campaign');
                    }else{
                        this.setState({ editcampid : res.data.data[0].id });
                        this.setState({ campname : res.data.data[0].name });
                    }
                }
            });
        }
    }
    handleChange(e){
        this.setState({[e.target.name] : e.target.value });
    }
    encrypt(text){
        var cipher = crypto.createCipher('aes-256-cbc','vidzon@#$%^&*()!')
        var crypted = cipher.update(text,'utf8','hex')
        crypted += cipher.final('hex');
        return crypted;
    }
    decrypt(text){
        var decipher = crypto.createDecipher('aes-256-cbc','vidzon@#$%^&*()!')
        var dec = decipher.update(text,'hex','utf8')
        dec += decipher.final('utf8');
        return dec;
    }
    validateForm(){
        const emptycampname = validator.isEmpty(this.state.campname, {ignore_whitespace:true});
        if(emptycampname){
            this.setState({ alertclass : 'error', alertmessage : 'Field can not be empty!' });
            return false;
        }
        return true;
    }
    createCampaign(e){
        e.preventDefault();
        const isValid = this.validateForm();
        const uid = this.decrypt(cookie.load('accesstoken'));
        if(isValid){
            //post signin data to the server
            if(this.decrypt(cookie.load('accesstoken'))){
                API.post('/api/add_edit_campaign', {
                    id : this.state.editcampid || 0, 
                    user_id : uid,
                    name : this.state.campname
                })
                .then(res => {
                    console.log(res);
                    //let campid = this.encrypt(''+ res.data.data.id +'');
                    if(res.data.status === 'success'){
                        let campid = this.props.match.params.campid;
                        if(campid){
                            this.props.history.push('/get-video/' + this.props.match.params.campid);
                        }else{
                            this.props.history.push('/get-video/' + res.data.data.id);
                        }
                        this.setState({ alertclass : 'success', alertmessage : res.data.message });
                        
                    }else{
                        this.setState({ alertclass : 'error', alertmessage : res.data.message });
                    }
                     
                });
            }else{
                this.setState({ alertclass : 'error', alertmessage : 'Something went wrong, please Login again.' });
            } 
            setTimeout(() => {
                this.setState({alertclass : ''});
                this.setState({alertmessage : ''});
            }, 3000);
        }else{
            setTimeout(() => {
                this.setState({alertclass : ''});
                this.setState({alertmessage : ''});
            }, 3000);
        }
    }

    render() { 
        return ( 
            <div className="vd_main_afterlogin">
                <Header />
                <Sidebar />
                <div className="vd_create_camp_progress">
                    <h1>Create New Campaign</h1>
                    <ul>
                        <li className="active">
                            <Link to={ this.props.match.params.campid ? '/create-campaign/' + this.props.match.params.campid : '/create-campaign'}></Link>
                            <span className="vd_cp_point"></span>
                            <span className="vd_cp_title">Create New Campaign</span>
                        </li>
                        <li>
                            <Link to={this.props.match.params.campid ? '/get-video/' + this.props.match.params.campid : '/create-campaign'}></Link>
                            <span className="vd_cp_point"></span>
                            <span className="vd_cp_title">Get Video</span>
                        </li>
                        <li>
                            <Link to={this.props.match.params.campid ? '/edit-video/' + this.props.match.params.campid : '/create-campaign'}></Link>
                            <span className="vd_cp_point"></span>
                            <span className="vd_cp_title">Edit Video</span>
                        </li>
                        <li>
                            <Link to={this.props.match.params.campid ? '/create-blog/' + this.props.match.params.campid : '/create-campaign'}></Link>
                            <span className="vd_cp_point"></span>
                            <span className="vd_cp_title">Create Blog</span>
                        </li>
                        <li>
                            <Link to={this.props.match.params.campid ? '/publish-on-youtube/' + this.props.match.params.campid : '/create-campaign'}></Link>
                            <span className="vd_cp_point"></span>
                            <span className="vd_cp_point p2"></span>
                            <span className="vd_cp_title">Publish On YouTube</span>
                        </li>
                    </ul>
                </div>

                <div className="vd_campBox_wrapper">
                    <div className="vd_create_camp_box">
                        <img src={process.env.PUBLIC_URL + '/assets/images/create_camp_icon.png'} alt="" /> <br/><br/><br/><br/>
                        <form onSubmit={this.createCampaign}>
                            <div className="vd_input_wrapper">
                                <input type="text" className="vd_input" placeholder="Enter Campaign Title" name="campname" value={this.state.campname}  onChange={this.handleChange} />
                            </div>
                            <button type="submit" className="vd_btn">Continue</button>
                        </form>
                    </div>
                </div>

                <AlertMessage alertcls={this.state.alertclass} alertmsg = {this.state.alertmessage} />
            </div>
        );
    }
}
 
export default CreateCampaign;