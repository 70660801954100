import React, { Component } from 'react';
import API from '../Api';
import { Link } from 'react-router-dom';
import validator from 'validator';

import AlertMessage from '../AlertMessage';

const initialState = {
    regemail : '', 
    
    alertclass : '',
    alertmessage : ''
}

class Forgot extends Component {
    constructor(props) {
        super(props);
        this.state = initialState;

        this.handleChange = this.handleChange.bind(this);
        this.forgotPassword = this.forgotPassword.bind(this);
    }

    handleChange(e){
        this.setState({[e.target.name] : e.target.value });
    }

    validateForm(){
        const emptyemail = validator.isEmpty(this.state.regemail, {ignore_whitespace:true});
        const isemail = validator.isEmail(this.state.regemail);

        if(emptyemail){
            this.setState({ alertclass : 'error', alertmessage : 'Field can not be empty!' });
            return false;
        }
        if(!isemail){
            this.setState({ alertclass : 'error', alertmessage : 'Email Is not valid!' });
            return false;
        }
        
        return true;
        
    }

    forgotPassword(e){
        e.preventDefault();

        const isValid = this.validateForm();
        if(isValid){
            //post signin data to the server 
            API.post('/api/forget', {
                email : this.state.regemail
            })
            .then(res => {
                if(res.data.status === 'success'){
                    this.setState(initialState);
                    this.setState({ alertclass : 'success', alertmessage : res.data.message });
                    setTimeout(() => {
                        this.props.history.push("/");
                    }, 2000);
                }else{
                    this.setState({ alertclass : 'error', alertmessage : res.data.message });
                }
            });

            setTimeout(() => {
                this.setState({alertclass : ''});
                this.setState({alertmessage : ''});
            }, 3000);
        }else{
            setTimeout(() => {
                this.setState({alertclass : ''});
                this.setState({alertmessage : ''});
            }, 3000);
        }

    }

    render() { 
        return ( 
            <div className="vd_auth_wrapper">
                <div className="vd_authinner_wrapper">
                    <div className="vd_auth_image">
                        <img src={process.env.PUBLIC_URL + '/assets/images/authimg.png'} alt="Vidzone" />
                    </div>
                    <div className="vd_auth_content">
                        <br/><br/><br/>
                        <img src={process.env.PUBLIC_URL + '/assets/images/logo.png'} alt="Vidzone Logo" />
                        <h1>Forgot Password?</h1>
                        <form onSubmit={this.forgotPassword}>
                            <div className="vd_authinput_wrapper">
                                <input type="text" className="vd_input" placeholder="Enter Your Registered Email" name="regemail" value={this.state.regemail} onChange={this.handleChange} />
                            </div>
                            <button type="submit" className="vd_btn">Send</button>
                            <div className="vd_targetlink">Already have an account? <Link to="/">Sign in</Link></div>
                        </form>
                        <br/><br/><br/>
                    </div>
                </div>
                <AlertMessage alertcls={this.state.alertclass} alertmsg = {this.state.alertmessage} />
            </div>
        );
    }
}
 
export default Forgot;