import React, { Component } from 'react';
import { Link } from 'react-router-dom';
class Page404 extends Component {
    constructor(props) {
        super(props);
        this.state = {  }
    }
    render() { 
        return ( 
            <div className="vd_error_page">
                <img src={ process.env.PUBLIC_URL + '/assets/images/error404.gif' } alt="" />
                <h1>Page not found!</h1>
                <Link to="/" className="vd_btn"> Back to Home</Link>
            </div>
        );
    }
}
 
export default Page404;