import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import validator from 'validator';
import crypto from 'crypto';
import cookie from 'react-cookies';
import API from '../../Api';
import { GoogleLogin } from 'react-google-login';

import AlertMessage from '../../AlertMessage';

import Header from '../Header';
import Sidebar from '../Sidebar';

let baseURL = 'https://vidzon.node.pixelnx.com';

class PublishOnYouTube extends Component {
    constructor(props) {
        super(props);
        this.state = {
            videourl : '',
            videoname : '',
            videoTitle : '',
            videoDescription : '',
            
            tagSearchKey : '',
            tagList : [],
            videoTags : [],
            
            alertclass : '',
            alertmessage : ''
        }
        this.playVideo = this.playVideo.bind(this);
        this.handleChange = this.handleChange.bind(this);
        this.PublishVideo = this.PublishVideo.bind(this);
        this.responseGoogle = this.responseGoogle.bind(this);
        this.handleTagChange = this.handleTagChange.bind(this);
        this.handleTagSubmit = this.handleTagSubmit.bind(this);
        this.tagDropdownCloseFun = this.tagDropdownCloseFun.bind(this);
        this.SelectTagFun = this.SelectTagFun.bind(this);
        this.removeTagFun = this.removeTagFun.bind(this);
    }
    decrypt(text){
        var decipher = crypto.createDecipher('aes-256-cbc','vidzon@#$%^&*()!')
        var dec = decipher.update(text,'hex','utf8')
        dec += decipher.final('utf8');
        return dec;
    }
    componentDidMount(){
        
        let campid = this.props.match.params.campid;
        const uid = this.decrypt(cookie.load('accesstoken'));
        document.body.classList.add('open-loader');
        if(campid){
            API.get('api/list_campaign/'+ uid +'/'+ campid)
            .then(res => {
                if(res.data.status === 'success'){
                    if(res.data.data.length === 0){
                        this.props.history.push('/create-campaign');
                    }else{
                        let vstatus = res.data.data[0].video_status;
                        if(vstatus === 'FINISH'){
                            if(res.data.data[0].edited_video_url){
                                let vidURL = baseURL + '/aws_videos/'+ campid + '/' + res.data.data[0].edited_video_url;
                                this.setState({ videourl : vidURL });
                                this.setState({ videoname : res.data.data[0].edited_video_url });
                            }else{
                                let vidURL = baseURL + '/aws_videos/'+ campid + '/' + res.data.data[0].compile_video_url;
                                this.setState({ videourl : vidURL });
                                this.setState({ videoname : res.data.data[0].compile_video_url });
                            }                           
                            
                        }else{
                            this.props.history.push('/get-video/' + campid);    
                        }
                        document.body.classList.remove('open-loader');
                    }
                }
            });
        }
    }
    
    playVideo(){
        this.refs.video.play();
        this.refs.videoplay.classList.add('videoplaying');
    }

    validateForm(){
        const emptytitle = validator.isEmpty(this.state.videoTitle, {ignore_whitespace:true});
        const emptydesc = validator.isEmpty(this.state.videoDescription, {ignore_whitespace:true});
        
        if(emptytitle){
            this.setState({ alertclass : 'error', alertmessage : 'Video Title field can not be empty!' });
            return false;
        }
        if(emptydesc){
            this.setState({ alertclass : 'error', alertmessage : 'Video Description field can not be empty!' });
            return false;
        }
        return true;
    }
    handleChange(e){
        this.setState({[e.target.name] : e.target.value });
    }
    PublishVideo(){
        const isValid = this.validateForm();
        if(isValid){
            this.googleBtnRef.click();
        }
        setTimeout(() => {
            this.setState({alertclass : ''});
            this.setState({alertmessage : ''});
        }, 3000);
    }
    responseGoogle = (res) => {
        const isValid = this.validateForm();
        if(isValid){
            if(res.error){
                this.setState({ alertclass : 'error', alertmessage : 'User closed the popup!' });
            }else{
                document.body.classList.add('open-loader');
                let campid = this.props.match.params.campid;
                let uid = this.decrypt(cookie.load('accesstoken'));
                let ytPost = {};
                ytPost.user_id = uid;
                ytPost.campaign_id = campid;
                ytPost.source = 'YOUTUBE';
                ytPost.post_title = this.state.videoTitle;
                ytPost.post_content = this.state.videoDescription;
                ytPost.post_status = 'public';
                ytPost.tags = this.state.videoTags;
                ytPost.video_url = this.state.videoname;
                ytPost.ytres = res
                API.post('api/ytpost', ytPost)
                .then(res1 => {
                    document.body.classList.remove('open-loader');
                    if(res1.data.status === "success"){
                        this.setState({ alertclass : 'success', alertmessage : res1.data.message });
                    }else{
                        this.setState({ alertclass : 'error', alertmessage : res1.data.message });
                    }
                    setTimeout(() => {
                        this.setState({alertclass : ''});
                        this.setState({alertmessage : ''});
                    }, 3000);
                });
                setTimeout(() => {
                    this.setState({alertclass : ''});
                    this.setState({alertmessage : ''});
                }, 3000);
            }
        }
        setTimeout(() => {
            this.setState({alertclass : ''});
            this.setState({alertmessage : ''});
        }, 3000);
    }

    handleTagChange(e){
        this.setState({ tagSearchKey : e.target.value });
    }
    handleTagSubmit(e){
        e.preventDefault();
        if(this.state.tagSearchKey){
            let keyword = this.state.tagSearchKey;
            API.post('/api/search_yt_tags_keyword', {keyword})
            .then(res => {
                this.refs.tagDropdown.classList.add('tag_dd_open');
                if(res.data){
                    if(res.data.elements[0].elements){
                        let tagdata = res.data.elements[0].elements;
                        this.setState({ tagList : tagdata });
                    }
                }else{
                    this.setState({ tagList : [] });
                    this.refs.tagDropdown.classList.remove('tag_dd_open');
                }
            });
        }
        if(this.state.tagSearchKey.length === 0){
            this.setState({ tagList : [] });
            this.refs.tagDropdown.classList.remove('tag_dd_open');
        }
    }
    tagDropdownCloseFun(){
        this.setState({ tagSearchKey : '' });
        this.setState({ tagList : [] });
        this.refs.tagDropdown.classList.remove('tag_dd_open');
    }
    SelectTagFun(tag){
        this.state.videoTags.push(tag);
        this.setState(this.state.videoTags);
    }
    removeTagFun(index){
        this.state.videoTags.splice(index, 1);
        this.setState(this.state.videoTags);
    }
    
    render() { 
        return ( 
            <div className="vd_main_afterlogin">
                <Header />
                <Sidebar />
                <div className="vd_create_camp_progress">
                    <h1>Publish On YouTube</h1>
                    <ul>
                        <li>
                            <Link to={'/create-campaign/' + this.props.match.params.campid}></Link>
                            <span className="vd_cp_point"></span>
                            <span className="vd_cp_title">Create New Campaign</span>
                        </li>
                        <li className="active">
                            <Link to={'/get-video/' + this.props.match.params.campid}></Link>
                            <span className="vd_cp_point"></span>
                            <span className="vd_cp_title">Get Video</span>
                        </li>
                        <li className="active">
                            <Link to={'/edit-video/' + this.props.match.params.campid}></Link>
                            <span className="vd_cp_point"></span>
                            <span className="vd_cp_title">Edit Video</span>
                        </li>
                        <li className="active">
                            <Link to={'/create-blog/' + this.props.match.params.campid}></Link>
                            <span className="vd_cp_point"></span>
                            <span className="vd_cp_title">Create Blog</span>
                        </li>
                        <li className="active">
                            <Link to={'/publish-on-youtube/' + this.props.match.params.campid}></Link>
                            <span className="vd_cp_point"></span>
                            <span className="vd_cp_point p2"></span>
                            <span className="vd_cp_title">Publish On YouTube</span>
                        </li>
                    </ul>
                </div>

                <div className="vd_campBox_wrapper">
                    
                    <div className="vd_createBlog_wrapper">
                        <div className="vd_createBlog_content">
                            <div className="vd_createBlog_header">
                                <div className="vd_header_left">
                                    <div className="vd_postTitle_input">
                                        <input type="text" placeholder="Your Video Title" name="videoTitle" value={this.state.videoTitle} onChange={this.handleChange} />
                                    </div>
                                </div>
                                <div className="vd_header_right">
                                    <button type="button" onClick={this.PublishVideo} className="vd_btn vd_btn_green">Publish</button>
                                    <GoogleLogin
                                        clientId="771812489546-obi4s1rdqvhimmmrbmopmp0m6bbgufjr.apps.googleusercontent.com"
                                        render={renderProps => ( 
                                            <button className="vd_btn vd_btn_green" style={{display: 'none'}} ref={googleref => this.googleBtnRef = googleref} onClick={renderProps.onClick} disabled={renderProps.disabled}>Publish</button>
                                        )}
                                        buttonText="Publish"
                                        scope="https://www.googleapis.com/auth/youtube.upload"
                                        onSuccess={this.responseGoogle}
                                        onFailure={this.responseGoogle}
                                    />
                                </div>
                            </div>
                            <div className="vd_createBlog_body">
                                <div className="vd_video_wrapper" ref="videoplay">
                                    <video ref="video" width="320" height="240" src={this.state.videourl}></video>
                                    <div className="vd_video_overlay" onClick={this.playVideo}>
                                        <span className="vd_video_play_btn">
                                            <img src={ process.env.PUBLIC_URL + '/assets/images/icons/play.svg' } alt="" />
                                        </span>
                                    </div>
                                </div>
                                <div className="vd_post_content">
                                    <div className="vd_post_description">
                                        <h3>Video Description</h3>
                                        <textarea rows="6" name="videoDescription" value={this.state.videoDescription} onChange={this.handleChange} />
                                    </div>
                                    
                                    <div className="vd_input_wrapper vd_tag_wrapper" ref="tagDropdown">
                                        <div className={this.state.videoTags.length === 0 ? 'vd_hide' : 'vd_added_tag_list'}>
                                            {this.state.videoTags.map((tag, index) =>
                                                <span key={index}>{tag} <span className="vd_tag_close" title="Remove" onClick={ (e) => this.removeTagFun((index), e)}></span></span>
                                            )}                                                
                                        </div>
                                        <span className="vd_tag_dd_close" title="Close" onClick={this.tagDropdownCloseFun}></span>
                                        <form onSubmit={this.handleTagSubmit}>
                                            <input type="text" className="vd_input" placeholder="Search Tag Here" name="tagSearchKey" value={this.state.tagSearchKey} onChange={this.handleTagChange} />
                                        </form>
                                        <div className="vd_tag_dropdown">
                                            <ul>
                                                {this.state.tagList.map((tag, index) => 
                                                    <li key={index} onClick={ () => this.SelectTagFun(tag.elements[0].attributes.data)}>{tag.elements[0].attributes.data}</li>
                                                )}
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>

                <AlertMessage alertcls={this.state.alertclass} alertmsg = {this.state.alertmessage} />
            </div>
        );
    }
    
}
 
export default PublishOnYouTube;