import React, { Component } from 'react';
import API from '../../Api';
import { Link } from 'react-router-dom';
import validator from 'validator';
import crypto from 'crypto';
import cookie from 'react-cookies';

import Swiper from 'swiper';
import '../../../../node_modules/swiper/dist/css/swiper.min.css';

import AlertMessage from '../../AlertMessage';

import Header from '../Header';
import Sidebar from '../Sidebar';

let baseURL = 'https://vidzon.node.pixelnx.com';

class GetVideo extends Component {
    constructor(props) {
        super(props);
        this.state = {
            campaignid : '',
            campaignname : '',
            producturl : '',
            productVideo : [],
            //campvideoCheck : false,
            
            pdetail_title : '',
            pdetail_images : [],
            pdetail_reviewiframe : '',
            pdetail_features : [],

            videosTray : [],
            /* compileVideos : {
                campaignID : '',
                
            }, */
            introID : '',
            outroID : '',
            videoQueue : [],

            awsDataLocale: '',

            videopopupurl : '',
            GetProductURL : '',

            /* introid : '',
            outroid : '', */

            introActive : '',
            outroActive : '',

            vidstatus : '',

            alertclass : '',
            alertmessage : ''
        }
        
        this.handleChange = this.handleChange.bind(this);
        this.getVideoDetail = this.getVideoDetail.bind(this);
        this.campvideoCheckFun = this.campvideoCheckFun.bind(this);
        this.deleteCampVideo = this.deleteCampVideo.bind(this);
        this.openPopup = this.openPopup.bind(this);
        this.closePopup = this.closePopup.bind(this);
        this.openVideoPopup = this.openVideoPopup.bind(this);
        this.handleChangeIntro = this.handleChangeIntro.bind(this);
        this.compileVideoFun = this.compileVideoFun.bind(this);
    }
    componentDidMount(){
        let campid = this.props.match.params.campid;
        const uid = this.decrypt(cookie.load('accesstoken'));
        if(campid){
            API.get('api/list_campaign/'+ uid +'/'+ campid)
            .then(res => {
                if(res.data.status === 'success'){
                    if(res.data.data.length === 0){
                        if(this.state.campaignid){
                            this.props.history.push('/get-video/' + this.state.campaignid);
                        }else{
                            this.props.history.push('/create-campaign');
                        }
                    }else{
                        let vstatus = res.data.data[0].video_status;
                        this.setState({ vidstatus : vstatus });
                        if(vstatus === 'RUNNING'){
                            this.refs.continuebtn.classList.add('vd_btn_disable');
                            this.refs.compilingvideo.classList.add('compilingvideo');
                        }else if(vstatus === 'NONE'){
                            this.refs.continuebtn.classList.add('vd_btn_disable');
                            this.refs.compilingvideo.classList.remove('compilingvideo');
                        }
                        else{
                            this.refs.compilingvideo.classList.remove('compilingvideo');
                        }
                        this.setState({ campaignid : res.data.data[0].id });
                        this.setState({ campaignname : res.data.data[0].name });
                    }
                }
            });

            

            API.get('api/list_campaign_videos/'+ uid +'/'+ campid)
            .then(res => {
                let tempArr = [];
                let productVideoStatic = res.data.data;
                if(res.data.status === 'success' && res.data.data.length > 0){
                    productVideoStatic.forEach(element => {
                        if(element.type === 'VIDEO'){
                            tempArr.push(element);
                        }
                        if(element.type === 'INTRO'){
                            this.setState({ introActive : 'active' });
                        }
                        if(element.type === 'OUTRO'){
                            this.setState({ outroActive : 'active' });
                        }
                    });

                    let findIntroId = productVideoStatic.find(item => item.type === 'INTRO');
                    let findOutroId = productVideoStatic.find(item => item.type === 'OUTRO');

                    this.intid = (findIntroId === undefined)?0:findIntroId.id;
                    this.outid = (findOutroId === undefined)?0:findOutroId.id;
                    let campid = this.props.match.params.campid;
                    this.setState({ 
                        campaignID : campid,
                        introID : this.intid,
                        outroID : this.outid
                    });
                }
                this.setState({productVideo : tempArr});
                this.swiper.update();
            });

        }else{
            this.props.history.push('/create-campaign');
        }

        /* slider start */
        this.swiper = new Swiper('.vd_product_slider', {
            slidesPerView: 5,
            spaceBetween: 30,
            navigation: {
                nextEl: '.swiper-button-next',
                prevEl: '.swiper-button-prev',
            },
            breakpoints: {
                1280: {
                    slidesPerView: 3,
                    spaceBetween: 20
                },
                1024: {
                  slidesPerView: 2,
                  spaceBetween: 20
                },
                768: {
                  slidesPerView: 1,
                  spaceBetween: 20
                }
            }
        });
        /* slider end */

        /* slider start */
        this.detailswiper = new Swiper('.vd_product_detail_slider', {
            slidesPerView: 4,
            spaceBetween: 20,
            navigation: {
                nextEl: '.swiper-button-next',
                prevEl: '.swiper-button-prev',
            },
            breakpoints: {
                1024: {
                  slidesPerView: 3,
                  spaceBetween: 30
                },
                768: {
                  slidesPerView: 2,
                  spaceBetween: 30
                },
                640: {
                  slidesPerView: 1,
                  spaceBetween: 20
                }
            }
        });
        /* slider end */


        /* get locale start */
        //let uid = this.decrypt(cookie.load('accesstoken'));
        API.post('api/user_app_settings/' + uid, {
            type : 'amazon',
            user_id : uid
        })
        .then(res => {
            let awsData_locale = res.data.data.awsData.locale;
            if(awsData_locale){
                if(res.data.status === 'success'){
                    awsData_locale = awsData_locale.replace('webservices.amazon.', '');
                    this.setState({ awsDataLocale : awsData_locale });
                    let getprducturl = 'https://amazon.'+ awsData_locale;
                    this.setState({ GetProductURL : getprducturl });
                }
            }
            
        });
        /* get locale end */

    }
    handleChange(e){
        this.setState({[e.target.name] : e.target.value });
    }
    campvideoCheckFun(vid, e){
        let findItem = this.state.productVideo.find(item => item.id === vid);
        if(e.target.checked === true){
            this.state.videosTray.push(findItem);
            this.state.videoQueue.push(findItem.id);
        }else{
            let index = this.state.videosTray.indexOf(findItem);
            if (index > -1) {
                this.state.videosTray.splice(index, 1);
                this.state.videoQueue.splice(index, 1);
            }
        }
        this.setState(this.state.videosTray);
    }
    deleteCampVideo(vid, e){
        const r = window.confirm("Do you really want to Delete?");
        if(r === true){ 
            //let campid = this.props.match.params.campid;
            const uid = this.decrypt(cookie.load('accesstoken'));
            API.get('api/delete_campaign_video/'+ uid +'/'+ vid)
            .then(res => {
                if(res.data.status === 'success'){
                    let findItem = this.state.productVideo.find(item => item.id === vid);
                    let index = this.state.productVideo.indexOf(findItem);
                    if (index > -1) {
                        this.state.productVideo.splice(index, 1);
                    }
                    let findItem1 = this.state.videosTray.find(item => item.id === vid);
                    let index1 = this.state.videosTray.indexOf(findItem1);
                    if (index1 > -1) {
                        this.state.videosTray.splice(index1, 1);
                        this.state.videoQueue.splice(index1, 1);
                    }

                    this.swiper.update();
                    this.setState(this.state.productVideo);
                    this.setState(this.state.videosTray);
                    this.setState({ alertclass : 'success', alertmessage : res.data.message });
                }else{
                    this.setState({ alertclass : 'error', alertmessage : res.data.message });
                }
            });
            setTimeout(() => {
                this.setState({alertclass : ''});
                this.setState({alertmessage : ''});
            }, 3000);
        }
        
    }
    decrypt(text){
        var decipher = crypto.createDecipher('aes-256-cbc','vidzon@#$%^&*()!')
        var dec = decipher.update(text,'hex','utf8')
        dec += decipher.final('utf8');
        return dec;
    }
    validateForm(){
        let emptyproducturl = validator.isEmpty(this.state.producturl, {ignore_whitespace:true});
        let isurl = validator.isURL(this.state.producturl, {protocols: ['https'], require_protocol: true});

        var regex = RegExp("([\\w-]+/)?(dp|gp/product)/(\\w+/)?(\\w{10})");
        var ASIN = this.state.producturl.match(regex);
        if(emptyproducturl){
            this.setState({ alertclass : 'error', alertmessage : 'Field can not be empty!' });
            return false;
        }

        let locales = this.state.awsDataLocale;
        if(locales === 'com'){
            this.localeurl = 'amazon.' + this.state.awsDataLocale;
        }else if(locales === 'uk'){
            this.localeurl = 'amazon.co.' + this.state.awsDataLocale;
        }else if(locales === 'au'){
            this.localeurl = 'amazon.com.' + this.state.awsDataLocale;
        }else if(locales === 'br'){
            this.localeurl = 'amazon.com.' + this.state.awsDataLocale;
        }else{
            this.localeurl = 'amazon.' + this.state.awsDataLocale;
        }


        let url = this.state.producturl;
        if(url.indexOf(this.localeurl) === -1) {
            this.setState({ alertclass : 'error', alertmessage : 'This URL not a valid from locale you save in settings.' });
            return false;
        }

        if(!ASIN){
            this.setState({ alertclass : 'error', alertmessage : 'This URL not a valid Product URL from Amazon.' });
            return false;
        }
        
        if(!isurl){
            this.setState({ alertclass : 'error', alertmessage : 'Please enter valid URL. Also use HTTPS' });
            return false;
        }
        return true;
    }
    getVideoDetail(e){
        e.preventDefault();
        let isValid = this.validateForm();
        let uid = this.decrypt(cookie.load('accesstoken'));
        let campid = this.props.match.params.campid;
        if(isValid){
            document.body.classList.add('open-loader');
            //post signin data to the server
            if(this.decrypt(cookie.load('accesstoken'))){
                API.post('/api/aswproducturl', {
                    campaign_id : campid, 
                    user_id : uid,
                    url : this.state.producturl
                })
                .then(res => {
                    if(res.data.status === 'success'){
                        this.state.productVideo.push(res.data.data);
                        this.setState({ alertclass : 'success', alertmessage : res.data.message });
                        this.setState({ producturl : '' });
                    }else{
                        this.setState({ alertclass : 'error', alertmessage : res.data.message });
                    }
                    setTimeout(() => {
                        this.setState({alertclass : ''});
                        this.setState({alertmessage : ''});
                    }, 3000);
                    this.swiper.update();
                    document.body.classList.remove('open-loader');
                });
            }else{
                this.setState({ alertclass : 'error', alertmessage : 'Something went wrong, please Login again.' });
            } 
            setTimeout(() => {
                this.setState({alertclass : ''});
                this.setState({alertmessage : ''});
            }, 3000);
        }else{
            setTimeout(() => {
                this.setState({alertclass : ''});
                this.setState({alertmessage : ''});
            }, 3000);
        }
    }

    openPopup(aws_data, asin , e){
        document.body.classList.add('open_popup');
        let productDetail = JSON.parse(aws_data);
        if(asin && productDetail[asin]){
            document.body.classList.add('popup_data_loaded');
            // title
            let title = productDetail[asin].ItemInfo.Title.DisplayValue;
            if(title){
                this.setState({ pdetail_title : title });
            }else{
                this.setState({ alertclass : 'error', alertmessage : 'Something went wrong, please Try again.' });
            }

            //images
            let images = productDetail[asin].Images.Variants;
            if(images){
                this.setState({ pdetail_images : images });
            }else{
                this.setState({ alertclass : 'error', alertmessage : 'Something went wrong, please Try again.' });
            }
            
            /* //review iframe
            let iframe = productDetail[0].CustomerReviews.IFrameURL;
            if(iframe){
                this.setState({ pdetail_reviewiframe : iframe });
            }else{
                this.setState({ alertclass : 'error', alertmessage : 'Something went wrong, please Try again.' });
            } */

            // features 
            let features = productDetail[asin].ItemInfo.Features.DisplayValues;
            if(features){
                this.setState({ pdetail_features : features });
            }else{
                this.setState({ alertclass : 'error', alertmessage : 'Something went wrong, please Try again.' });
            }

            setTimeout(() => {
                this.detailswiper.update();
            }, 50);
            setTimeout(() => {
                this.setState({alertclass : ''});
                this.setState({alertmessage : ''});
            }, 3000);
        }
        
    }

    closePopup(){
        document.body.classList.remove('open_popup');
        document.body.classList.remove('open_videopopup');
        document.body.classList.remove('popup_data_loaded');
        this.setState({
            pdetail_title : '',
            pdetail_images : [], 
            pdetail_reviewiframe : '',
            videopopupurl: ''
        });
        
    }

    openVideoPopup(vurl){
        document.body.classList.add('open_videopopup');
        let imgURL = baseURL + '/aws_videos/'+ this.state.campaignid + '/' + vurl;
        this.setState({ videopopupurl : imgURL });
        this.refs.video.play();
    }

    handleChangeIntro(e){
        document.body.classList.add('open-loader');
        let uid = this.decrypt(cookie.load('accesstoken'));
        let file = e.target.files[0];
        let data = new FormData();
        let int_e = e.target.name;
        if(int_e === 'introfile'){
            data.append('type', 'INTRO');
        }
        if(e.target.name === 'outrofile'){
            data.append('type', 'OUTRO');
        }
        data.append('file', file);
        data.append('user_id', uid);
        data.append('camp_id', this.state.campaignid);

        if(file){
            if(file.type.includes('png') === true || file.type.includes('jpg') === true || file.type.includes('jpeg') === true || file.type.includes('mp4') === true){
                if(file.type.includes('image')){
                    data.append('createvideo', JSON.stringify({
                        duration: 5
                    }))
                    data.append('file_type', 'IMG');
                }
                else{
                    data.append('createvideo', false)
                    data.append('file_type', 'VDO');
                }
                API.post('/api/uploadIntroOutro', data)
                .then(res => {
                    if(res.data.status === 'success'){
                        if(int_e === 'introfile'){
                            this.setState({ introID : res.data.data.id });
                            this.setState({ introActive : 'active' });
                        }
                        if(int_e === 'outrofile'){
                            this.setState({ outroID : res.data.data.id });
                            this.setState({ outroActive : 'active' });
                        }
    
                        this.setState({ alertclass : 'success', alertmessage : res.data.message });
                        document.body.classList.remove('open-loader');
                    }else{
                        this.setState({ alertclass : 'error', alertmessage : res.data.message });
                    }
                })
            }else{
                document.body.classList.remove('open-loader');
                this.setState({ alertclass : 'error', alertmessage : 'You can upload only Image and Video! (allowed format is png,jpg,jpeg and mp4)' });
            }
    
        }else{
            document.body.classList.remove('open-loader');
            this.setState({ alertclass : 'error', alertmessage : 'You cancel the upload.' });
        }

        setTimeout(() => {
            this.setState({alertclass : ''});
            this.setState({alertmessage : ''});
        }, 3000);
        
    }
    
    compileVideoFun(e){
        const compArray = {
            'campaignID' : this.state.campaignid,
            'intro' : this.state.introID,
            'outro' : this.state.outroID,
            'videoQueue' : this.state.videoQueue
        }
        if((compArray.intro || compArray.outro) && compArray.videoQueue.length > 0){
            this.refs.compilingvideo.classList.add('compilingvideo');
            API.post('/api/merge', compArray)
            .then(res => {
                if(res.data.status === 'success'){
                    this.refs.compilingvideo.classList.remove('compilingvideo');
                    this.refs.continuebtn.classList.remove('vd_btn_disable');
                    this.setState({ alertclass : 'success', alertmessage : res.data.message });
                }
                setTimeout(() => {
                    this.setState({alertclass : ''});
                    this.setState({alertmessage : ''});
                }, 3000);
            });
        }else{
            this.setState({ alertclass : 'error', alertmessage : 'Add Atleast one Video into the tray.' });
        }
        setTimeout(() => {
            this.setState({alertclass : ''});
            this.setState({alertmessage : ''});
        }, 3000);
    }

    render() {
        
        return ( 
            <div className="vd_main_afterlogin">
                <Header />
                <Sidebar />
                <div className="vd_create_camp_progress">
                    <h1>{/* this.state.campaignname */}Get Video</h1>
                    <ul>
                        <li>
                            <Link to={'/create-campaign/' + this.props.match.params.campid}></Link>
                            <span className="vd_cp_point"></span>
                            <span className="vd_cp_title">Create New Campaign</span>
                        </li>
                        <li className="active">
                            <Link to={'/get-video/' + this.props.match.params.campid}></Link>
                            <span className="vd_cp_point"></span>
                            <span className="vd_cp_title">Get Video</span>
                        </li>
                        <li>
                            <Link to={ this.state.vidstatus === "FINISH" ? '/edit-video/' + this.props.match.params.campid : '/get-video/' + this.props.match.params.campid}></Link>
                            <span className="vd_cp_point"></span>
                            <span className="vd_cp_title">Edit Video</span>
                        </li>
                        <li>
                            <Link to={ this.state.vidstatus === "FINISH" ? '/create-blog/' + this.props.match.params.campid : '/get-video/' + this.props.match.params.campid}></Link>
                            <span className="vd_cp_point"></span>
                            <span className="vd_cp_title">Create Blog</span>
                        </li>
                        <li>
                            <Link to={ this.state.vidstatus === "FINISH" ? '/publish-on-youtube/' + this.props.match.params.campid : '/get-video/' + this.props.match.params.campid}></Link>
                            <span className="vd_cp_point"></span>
                            <span className="vd_cp_point p2"></span>
                            <span className="vd_cp_title">Publish On YouTube</span>
                        </li>
                    </ul>
                </div>

                <div className="vd_campBox_wrapper">
                    <form onSubmit={this.getVideoDetail}>
                        <div className="vd_getvideo_inputbox">
                            <div className="vd_input_wrapper">
                                <input type="text" className="vd_input" placeholder="Enter Product URL Here...." name="producturl" value={this.state.producturl} onChange={this.handleChange} />
                                <p>Get Product URL from here - <a href={this.state.GetProductURL} target="_blank" rel="noopener noreferrer">{this.state.GetProductURL}</a></p>
                            </div>
                            <button type="submit" className="vd_btn">Go</button>
                        </div>
                    </form>
                    <br/><br/>


                    <div className="vd_campaign_list video_list">
                        <div className="swiper-container vd_product_slider">
                            <div className="swiper-wrapper">
                                {this.state.productVideo.map((pvideo, index) =>
                                    <div key={pvideo.id} className="swiper-slide">
                                        <div className="vd_campaign" style={{ animationDelay : `${index * 50}ms` }}>
                                            <div className="vd_campimg">
                                                <div className="vd_checkbox">
                                                    <input type="checkbox" id={"productvid_" + pvideo.id} value={this.state.campvideoCheck} onChange={ (e) => this.campvideoCheckFun((pvideo.id), e)}/>
                                                    <label htmlFor={"productvid_" + pvideo.id}></label>
                                                </div>
                                                <img src={pvideo.aws_product_thumb} alt="" />
                                                <div className="vd_camp_overlay">
                                                    <span className="vd_campVid_delete" title="Delete" onClick={ (e) => this.deleteCampVideo((pvideo.id), e)}></span>
                                                    <span className="vd_camp_play_btn" onClick={ () => this.openVideoPopup(pvideo.video_url)}>
                                                        <img src={ process.env.PUBLIC_URL + '/assets/images/icons/play.svg' } alt="" />
                                                    </span>
                                                </div>
                                            </div>
                                            <button className="vd_btn vd_btn_outline" onClick={ (e) => this.openPopup(pvideo.aws_product_detail,pvideo.aws_asin,e) }>View Details</button>
                                        </div>
                                    </div>
                                )}
                            </div>
                            <div className="swiper-button-prev"></div>
                            <div className="swiper-button-next"></div>
                        </div>
                    </div>
                    <br/><br/>
                    
                    <div className="vd_note">
                        <strong>Note :</strong> If in case you are not uploading the Intro/Outro, then you have to upload at least 1 video to compile. If you upload the image as Intro/Outro, duration of that will be 5 seconds.   
                    </div>
                    
                    <div className="vd_compilevideo_wrapper" ref="compilingvideo">
                        <div className="vd_compilevideo_list">
                            <div className={'vd_compilevid_item intro_outro itm_intro '+ this.state.introActive }>
                                <img src={process.env.PUBLIC_URL + '/assets/images/icons/intro_outro.svg'} alt="" />
                                <div className="vd_intout_uploader">
                                    <input type="file" name="introfile" onChange={this.handleChangeIntro} accept="image/*,video/*" />
                                </div>
                                <div className="vd_intout_remove" title="Delete"></div>
                            </div>
                            {this.state.videosTray.map(compvid =>
                                <div key={compvid.id} className="vd_compilevid_item">
                                    <img src={compvid.aws_product_thumb} alt="" />
                                </div>
                            )}
                            <div className={'vd_compilevid_item intro_outro itm_outro '+ this.state.outroActive }>
                                <img src={process.env.PUBLIC_URL + '/assets/images/icons/intro_outro.svg'} alt="" />
                                <div className="vd_intout_uploader">
                                    <input type="file" name="outrofile" value={this.state.outrofile} onChange={this.handleChangeIntro} accept="image/*,video/*" />
                                </div>
                                <div className="vd_intout_remove" title="Delete"></div>
                            </div>
                        </div>
                        <div className="vd_compilevideo_button" onClick={this.compileVideoFun}>
                            <img src={ process.env.PUBLIC_URL + '/assets/images/icons/compile_video.svg' } alt="" />
                            <span>Compile Video</span>
                            <div className="vd_scene_working">
                                <div className="vd_build_loader_bar"></div>
                            </div>
                        </div>
                    </div>
                    <br/><br/>
                    <div className="text-center" ref="continuebtn">
                        <Link to={'/edit-video/' + this.props.match.params.campid } className="vd_btn">Continue</Link>
                    </div>

                </div>

                <div className="vd_popup_wrapper">
                    <div className="vd_popup_inner">
                        <div className="vd_popup_close" title="Close" onClick={this.closePopup}><span></span><span></span></div>
                        
                        <div className="vd_popup_loader">
                            <svg className="vd_spinner" width="30px" height="30px" viewBox="0 0 36 36" xmlns="http://www.w3.org/2000/svg">
                                <circle className="path" fill="none" strokeWidth="3" strokeLinecap="round" cx="18" cy="18" r="12"></circle>
                            </svg>
                            <p>Loading...</p>
                        </div>
                        <div className="vd_popup_body">
                            <h3 className="vd_product_title">{this.state.pdetail_title}</h3>
                            <h4 className="vd_product_subheading">Product Images</h4>
                            <div className="vd_product_images">
                                <div className="swiper-container vd_product_detail_slider">
                                    <div className="swiper-wrapper">
                                        {this.state.pdetail_images.map((img, index) =>
                                            <div key={index} className="swiper-slide">
                                                <img className="vd_pi_item" src={img.Large.URL} alt="" />
                                            </div>
                                        )}
                                    </div>
                                    <div className="swiper-button-prev"></div>
                                    <div className="swiper-button-next"></div>
                                </div>
                            </div>
                            {/* <h4 className="vd_product_subheading">Customer Review</h4>
                            <div className="review_iframe">
                                <iframe title={this.state.pdetail_title} src={this.state.pdetail_reviewiframe}></iframe>
                            </div> */}
                            <h4 className="vd_product_subheading">Features</h4>
                            <ul className="vd_product_features">
                                {this.state.pdetail_features.map((feature, index) => 
                                    <li key={index}>{feature}</li>    
                                )}
                            </ul>
                        </div>
                    </div>
                </div>

                <div className="vd_videopopup_wrapper">
                    <div className="vd_videopopup_inner">
                        <div className="vd_videopopup_close" title="Close" onClick={this.closePopup}><span></span><span></span></div>
                        <div className="vd_videopopup_body">
                            <div className="vd_video_player">
                                <video ref="video" width="320" height="240" src={this.state.videopopupurl} autoPlay></video>
                            </div>
                        </div>

                    </div>
                </div>

                <AlertMessage alertcls={this.state.alertclass} alertmsg = {this.state.alertmessage} />
            </div>
        );
    }
}
 
export default GetVideo;