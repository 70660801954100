import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import crypto from 'crypto';
import cookie from 'react-cookies';

import Header from './Header';
import Sidebar from './Sidebar';
import API from '../Api';

import { FacebookShareButton, FacebookIcon, TwitterShareButton, TwitterIcon, PinterestShareButton, PinterestIcon } from 'react-share';

let baseURL = 'https://vidzon.node.pixelnx.com';

const PostListNoData = (props) => {
    let d = props.postcount;
    if(d === 0){
        return <div className="campaign_nodata"><img src={process.env.PUBLIC_URL + 'assets/images/create_camp_icon.png'} alt="" /><p>There is no post created yet!</p></div>;
    }else{
        return null;
    }
}



class MyPost extends Component {
    constructor(props) {
        super(props);
        this.state = { 
            postList : []
        }
        
        this.getDescriptionFun = this.getDescriptionFun.bind(this);
    }
    componentDidMount(){
        let uid = this.decrypt(cookie.load('accesstoken'));
        API.post('/api/campaign_posts',{
            user_id:uid
        })
        .then(res => {
            if(res.data.status === "success"){
                res.data.data.forEach(element => {
                    let finalDes = ''
                    if(element.source === "WORDPRESS"){
                        let postContentStr = element.post_contet;
                        let desStr = postContentStr.split('[/embed]<br><br><p>');
                        let finalDes0 = desStr[1].split('</p><br><br>');
                        finalDes = finalDes0[0];
                    }else{
                        finalDes = element.post_contet
                    }

                    let rawdata = {
                        id : element.id,
                        campaign_id : element.campaign_id,
                        post_contet : finalDes,
                        title : element.title,
                        source : element.source,
                        post_url : element.post_url,
                    } 
                    this.state.postList.push(rawdata);
                    this.setState(this.state.postList);
                    console.log(this.state.postList);
                });
            }
        })
    }
    decrypt(text){
        var decipher = crypto.createDecipher('aes-256-cbc','vidzon@#$%^&*()!')
        var dec = decipher.update(text,'hex','utf8')
        dec += decipher.final('utf8');
        return dec;
    }
    getDescriptionFun(desc, e){
        let postContentStr = desc;
        let desStr = postContentStr.split('[/embed]<br><br><p>');
        let finalDes = desStr[1].split('</p><br><br>');
        console.log(finalDes[0]);
    }
    render() { 
        return ( 
            <div className="vd_main_afterlogin">
                <Header />
                <Sidebar />
                <div className="vd_content_wrapper">
                    <h3>My Post</h3>
                    
                    <PostListNoData postcount={this.state.postList.length} /> 

                    <div className="vd_mypost_list">

                        {this.state.postList.map((post, index) =>
                            <div key={post.id} className="vd_mypost_item" style={{ animationDelay : `${index * 50}ms` }}>
                                <div className="vd_mypost_thumb">
                                    <img src={baseURL + '/aws_videos/'+ post.campaign_id + '/video_thumbnail.jpg?v=' + Math.random()} alt="" />
                                </div>
                                <div className="vd_mypost_detail">
                                    <h3>{post.title}</h3>
                                    <p>{post.post_contet}</p>
                                    <div className={"vd_post_label " + post.source}>{post.source}</div>
                                </div>
                                <div className="vd_mypost_action">
                                    <Link className="vd_post_btn" to={'create-campaign/'+post.campaign_id} target="_blank" rel="noopener noreferrer"   title="Edit Campaign"><i className="material-icons">edit</i></Link>
                                    <a className="vd_post_btn" href={post.post_url} target="_blank" rel="noopener noreferrer" title="View"><i className="material-icons">call_made</i></a>
                                    <span className="vd_post_btn" title="Share">
                                        <i className="material-icons" data-toggle="dropdown">share</i>
                                        <div className="vd_post_share_dropdown">
                                            <div className="vd_post_share_inner">
                                                <div className="vd_postShare_item">
                                                    <FacebookShareButton url={post.post_url}>
                                                        <FacebookIcon size={32} round={true} />
                                                    </FacebookShareButton>
                                                </div>
                                                <div className="vd_postShare_item">
                                                    <TwitterShareButton url={post.post_url}>
                                                        <TwitterIcon size={32} round={true} />
                                                    </TwitterShareButton>
                                                </div>
                                                <div className="vd_postShare_item">
                                                    <PinterestShareButton url={post.post_url} media={baseURL + '/aws_videos/'+ post.campaign_id + '/video_thumbnail.jpg'}>
                                                        <PinterestIcon size={32} round={true} />
                                                    </PinterestShareButton>
                                                </div>
                                            </div>
                                        </div>
                                    </span>
                                </div>
                            </div>
                        )}

                    </div>
                </div>
            </div>
        );
    }
}
 
export default MyPost;