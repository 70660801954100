import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import validator from 'validator';
import crypto from 'crypto';
import cookie from 'react-cookies';
import API from '../../Api';

import DatePicker from "react-datepicker";
import '../../../../node_modules/react-datepicker/dist/react-datepicker.css';

import AlertMessage from '../../AlertMessage';

import Header from '../Header';
import Sidebar from '../Sidebar';

let baseURL = 'https://vidzon.node.pixelnx.com';

const ScheduleDate = (props) => {
    if(props.posttype === 'future'){
        return(
            <div className="vd_input_wrapper">
                <label>Schedule Date</label>
                <DatePicker
                    className="vd_input"
                    selected={props.startdate}
                    onChange={props.onchange}
                    minDate={new Date()}
                />
            </div>
        );
    }else{
        return null;
    }
}

class CreateBlog extends Component {
    constructor(props) {
        super(props);
        this.state = {
            compiledProduct : [],
            videourl : '',

            postTitle : '',
            postDescription : '',
            postProduct : [],
            postFinalProduct : [],

            startDate: '',
            wpList : [],
            wordpressId : '',
            wordpressName : '',
            categoryId : '',
            categoryList : [],
            postType : 'publish',

            alertclass : '',
            alertmessage : ''
        }
        this.playVideo = this.playVideo.bind(this);
        this.postAddProduct = this.postAddProduct.bind(this);
        this.handleChange = this.handleChange.bind(this);
        this.openPopup = this.openPopup.bind(this);
        this.handleDateChange = this.handleDateChange.bind(this);
        this.wpSelect = this.wpSelect.bind(this);
        this.CategorySelect = this.CategorySelect.bind(this);
        this.PostTypeSelect = this.PostTypeSelect.bind(this);
        this.PublishPost = this.PublishPost.bind(this);
    }
    decrypt(text){
        var decipher = crypto.createDecipher('aes-256-cbc','vidzon@#$%^&*()!')
        var dec = decipher.update(text,'hex','utf8')
        dec += decipher.final('utf8');
        return dec;
    }
    componentDidMount(){
        let campid = this.props.match.params.campid;
        const uid = this.decrypt(cookie.load('accesstoken'));
        API.get('api/list_campaign_videos/'+ uid +'/'+ campid)
        .then(res => {
            let pdata = [];
            console.log(res.data.data);            
            res.data.data.forEach(el => {
                if(el.type === "VIDEO"){
                    if(el.aws_product_detail){
                        let data = JSON.parse(el.aws_product_detail);
                        pdata.push(data[el.aws_asin]);
                        console.log(pdata);                        
                    }
                }
            });
            this.setState({ compiledProduct : pdata });
        });

        document.body.classList.add('open-loader');
        if(campid){
            API.get('api/list_campaign/'+ uid +'/'+ campid)
            .then(res => {
                if(res.data.status === 'success'){
                    if(res.data.data.length === 0){
                        this.props.history.push('/create-campaign');
                    }else{
                        let vstatus = res.data.data[0].video_status;
                        if(vstatus === 'FINISH'){
                            if(res.data.data[0].edited_video_url){
                                let vidURL = baseURL + '/aws_videos/'+ campid + '/' + res.data.data[0].edited_video_url;
                                this.setState({ videourl : vidURL });
                            }else{
                                let vidURL = baseURL + '/aws_videos/'+ campid + '/' + res.data.data[0].compile_video_url;
                                this.setState({ videourl : vidURL });
                            }
                            
                        }else{
                            this.props.history.push('/get-video/' + campid);    
                        }
                        document.body.classList.remove('open-loader');
                    }
                }
            });
        }

        /* get wordpress list start */
        API.get('api/list_wpblog/' + uid + '/0')
        .then(res =>{
            if(res.data.status === "success"){
                this.setState({ wpList : res.data.data });
            }
        });
        /* get wordpress list end */
    }
    
    playVideo(){
        this.refs.video.play();
        this.refs.videoplay.classList.add('videoplaying');
    }

    postAddProduct(asin, img, url, title, e){
        let postProdArray = {
            image : img,
            title : title,
            asin : asin,
            producturl : url    
        };
        console.log(postProdArray);        
        let findItem = this.state.compiledProduct.find(item => item.ASIN === asin);
        if(e.target.checked === true){
            this.state.postProduct.push(findItem);
            this.state.postFinalProduct.push(postProdArray);
        }else{
            let index = this.state.postProduct.indexOf(findItem);
            this.state.postProduct.splice(index, 1);
            this.state.postFinalProduct.splice(index, 1);
        }
        this.setState(this.state.postProduct);       
    }

    validateForm(){
        const emptytitle = validator.isEmpty(this.state.postTitle, {ignore_whitespace:true});
        const emptydesc = validator.isEmpty(this.state.postDescription, {ignore_whitespace:true});
        if(emptytitle){
            this.setState({ alertclass : 'error', alertmessage : 'Post Title field can not be empty!' });
            return false;
        }
        if(emptydesc){
            this.setState({ alertclass : 'error', alertmessage : 'Product Description field can not be empty!' });
            return false;
        }
        return true;
    }
    handleChange(e){
        this.setState({[e.target.name] : e.target.value });
    }
    openPopup(e){
        const isValid = this.validateForm();
        if(isValid){
            document.body.classList.add('open_popup');
            document.body.classList.add('popup_data_loaded');
        }    
        setTimeout(() => {
            this.setState({alertclass : ''});
            this.setState({alertmessage : ''});
        }, 3000);    
    }
    closePopup(){
        document.body.classList.remove('open_popup');
        document.body.classList.add('popup_data_loaded');
    }
    handleDateChange(date){
        this.setState({
            startDate: date
        });
    }
    wpSelect(e){
        let wpname = e.target.value;
        const uid = this.decrypt(cookie.load('accesstoken'));
        if(wpname === ''){
            this.setState({ categoryList : [] });
        }else{
            this.refs.vd_input_loading.classList.add('vd_input_loading');
            API.get('api/get_blog_category/' + uid + '/' + wpname)
            .then(res =>{
                if(res.data.status === "success"){
                    this.setState({ categoryList : res.data.data });
                    this.setState({ wordpressId : wpname });
                    this.refs.vd_input_loading.classList.remove('vd_input_loading');
                }
            });
        }
    }
    CategorySelect(e){
        let catid = e.target.value;
        this.setState({categoryId : catid});
    }
    PostTypeSelect(e){
        e.preventDefault();
        let posttype = e.target.value;
        this.setState({postType : posttype});
        if(posttype != "future"){
            this.setState({startDate : ''});
        }
    }
    
    validatePostForm(){
        const emptywp = validator.isEmpty(this.state.wordpressId, {ignore_whitespace:true});
        const emptycat = validator.isEmpty(this.state.categoryId, {ignore_whitespace:true});
        
        

        if(emptywp){
            this.setState({ alertclass : 'error', alertmessage : 'WordPress Website field can not be empty!' });
            return false;
        }
        if(emptycat){
            this.setState({ alertclass : 'error', alertmessage : 'Category field can not be empty!' });
            return false;
        }

        if(this.state.postType === 'future' && this.state.startDate === ''){
            this.setState({ alertclass : 'error', alertmessage : 'Schedule Date field can not be empty!' });
            return false;
        }

        return true;
    }
    PublishPost(e){
        e.preventDefault();
        const isValid = this.validatePostForm();
        let fulldate = '';
        if(this.state.startDate === ''){
            fulldate = '';
        }else{
            let date1 = new Date(this.state.startDate);
            let y = date1.getFullYear();
            let m = '' + (date1.getMonth() + 1);
            let d = '' + date1.getDate();
            if(m.length < 2) m = '0' + m;
            if(d.length < 2) d = '0' + d;
            fulldate = [y, m, d].join('-');
        }

        if(isValid){
            document.body.classList.add('open-loader');
            const uid = this.decrypt(cookie.load('accesstoken'));
            let campid = this.props.match.params.campid;
            let postData = {
                user_id : uid,
                blog_id : this.state.wordpressId,
                campaign_id : campid,
                post_status : this.state.postType,
                schedule_date : fulldate,
                category_id : this.state.categoryId,
                post_title : this.state.postTitle,
                post_content : this.state.postDescription,
                products : this.state.postFinalProduct,
                video_url : this.state.videourl
            };
            API.post('api/create_wpblog_post', postData)
            .then(res => {
                if(res.data.status === "success"){
                    this.setState({ alertclass : 'success', alertmessage : res.data.message });
                    document.body.classList.remove('open-loader');
                    this.closePopup();
                }else{
                    this.setState({ alertclass : 'error', alertmessage : res.data.message });
                    document.body.classList.remove('open-loader');
                }
            });
        }
            
        
        setTimeout(() => {
            this.setState({alertclass : ''});
            this.setState({alertmessage : ''});
        }, 3000);
    }
    
    render() { 
        return ( 
            <div className="vd_main_afterlogin">
                <Header />
                <Sidebar />
                <div className="vd_create_camp_progress">
                    <h1>Create Blog</h1>
                    <ul>
                        <li>
                            <Link to={'/create-campaign/' + this.props.match.params.campid}></Link>
                            <span className="vd_cp_point"></span>
                            <span className="vd_cp_title">Create New Campaign</span>
                        </li>
                        <li className="active">
                            <Link to={'/get-video/' + this.props.match.params.campid}></Link>
                            <span className="vd_cp_point"></span>
                            <span className="vd_cp_title">Get Video</span>
                        </li>
                        <li className="active">
                            <Link to={'/edit-video/' + this.props.match.params.campid}></Link>
                            <span className="vd_cp_point"></span>
                            <span className="vd_cp_title">Edit Video</span>
                        </li>
                        <li className="active">
                            <Link to={'/create-blog/' + this.props.match.params.campid}></Link>
                            <span className="vd_cp_point"></span>
                            <span className="vd_cp_title">Create Blog</span>
                        </li>
                        <li>
                            <Link to={'/publish-on-youtube/' + this.props.match.params.campid}></Link>
                            <span className="vd_cp_point"></span>
                            <span className="vd_cp_point p2"></span>
                            <span className="vd_cp_title">Publish On YouTube</span>
                        </li>
                    </ul>
                </div>

                <div className="vd_campBox_wrapper">
                    
                    <div className="vd_createBlog_wrapper">
                        <div className="vd_createBlog_sidebar">
                            <h3 className="vd_cs_title">Complied Products</h3>
                            <ul className="vd_comp_prod">
                                { this.state.compiledProduct.map((prod, index) => 
                                    <li key={index}>
                                        <input type="checkbox" value={index} onChange={ (e) => this.postAddProduct(prod.ASIN, prod.Images.Variants[0].Large.URL, prod.DetailPageURL,  prod.ItemInfo.Title.DisplayValue, e)} />
                                        <div className="vd_comp_prod_inner">
                                            <img src={prod.Images.Variants[0].Large.URL} alt="" />
                                            <p>{prod.ItemInfo.Title.DisplayValue}</p>
                                        </div>
                                    </li>
                                )}
                            </ul>
                        </div>
                        <div className="vd_createBlog_content">
                            <div className="vd_createBlog_header">
                                <div className="vd_header_left">
                                    <div className="vd_postTitle_input">
                                        <input type="text" placeholder="Your Post Title" name="postTitle" value={this.state.postTitle} onChange={this.handleChange} />
                                    </div>
                                </div>
                                <div className="vd_header_right">
                                    {/* <a className="vd_btn">Set As Default</a> &nbsp;&nbsp; */}
                                    <button type="button" onClick={this.openPopup} className="vd_btn vd_btn_green">Publish</button>
                                </div>
                            </div>
                            <div className="vd_createBlog_body">
                                <div className="vd_video_wrapper" ref="videoplay">
                                    <video ref="video" width="320" height="240" src={this.state.videourl}></video>
                                    <div className="vd_video_overlay" onClick={this.playVideo}>
                                        <span className="vd_video_play_btn">
                                            <img src={ process.env.PUBLIC_URL + '/assets/images/icons/play.svg' } alt="" />
                                        </span>
                                    </div>
                                </div>
                                <div className="vd_post_content">
                                    <div className="vd_post_description">
                                        <h3>Product Description</h3>
                                        <textarea rows="6" name="postDescription" value={this.state.postDescription} onChange={this.handleChange} />
                                    </div>
                                    <div className={this.state.postProduct.length === 0 ? 'vd_post_product_list vd_hide' : 'vd_post_product_list'} >
                                        <h3>Affiliate Products</h3>
                                        <ul>
                                            {this.state.postProduct.map((prod, index) =>
                                                <li key={index}>
                                                    <img src={prod.Images.Variants[0].Large.URL} alt="" />
                                                    <p>{prod.ItemInfo.Title.DisplayValue}</p>
                                                    <div className="vd_ppl_action">
                                                        <a href={prod.DetailPageURL} target="_blank" rel="noopener noreferrer" className="vd_ppl_btn">Buy Now</a>
                                                    </div>
                                                </li>
                                            )}
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <br/><br/>
                    <div className="text-center">
                        <Link to={'/publish-on-youtube/' + this.props.match.params.campid } className="vd_btn">Continue</Link>
                    </div>
                    
                </div>

                <div className="vd_popup_wrapper">
                    <div className="vd_popup_inner">
                        <div className="vd_popup_close" title="Close" onClick={this.closePopup}><span></span><span></span></div>
                        
                        <h3 className="vd_product_title">Publish Post</h3>
                        <div className="vd_popup_body">
                            <form onSubmit={this.PublishPost}>
                                <div className="vd_input_inline">
                                    <div className="vd_input_wrapper">
                                        <label>WordPress Website</label>
                                        <select className="vd_input" name="wordpressName" onChange={this.wpSelect}>
                                            <option value="">Select WordPress Website</option>
                                            {this.state.wpList.map(wplist =>
                                                <option key={wplist.id} value={wplist.id}>{wplist.name}</option>
                                            )}
                                            
                                        </select>
                                    </div>
                                    <div className="vd_input_wrapper" ref="vd_input_loading">
                                        <label>Category</label>
                                        <select className="vd_input" onChange={this.CategorySelect}>
                                            <option>Select Category</option>
                                            {this.state.categoryList.map(catid =>
                                                <option key={catid.categoryId} value={catid.categoryId}>{catid.categoryName}</option>
                                            )}                           
                                        </select>
                                        <div className="vd_input_loader">
                                            <svg className="vd_spinner" width="30px" height="30px" viewBox="0 0 36 36" xmlns="http://www.w3.org/2000/svg"><circle className="path" fill="none" strokeWidth="3" strokeLinecap="round" cx="18" cy="18" r="12"></circle></svg>
                                        </div>
                                    </div>
                                </div>
                                <div className="vd_input_inline">
                                    <div className="vd_input_wrapper">
                                        <label>Post As</label>
                                        <select className="vd_input" onChange={this.PostTypeSelect}>
                                            <option value="publish">Publish</option>
                                            <option value="future">Schedule</option>
                                            <option value="draft">Draft</option>
                                        </select>
                                    </div>
                                    <ScheduleDate posttype={this.state.postType} startdate={this.state.startDate} onchange={this.handleDateChange} />
                                    
                                </div>
                                <div className="text-center">
                                    <button type="submit" className="vd_btn vd_btn_green">Publish Post</button>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>

                <AlertMessage alertcls={this.state.alertclass} alertmsg = {this.state.alertmessage} />
            </div>
        );
    }
    
}
 
export default CreateBlog;