import React, { Component } from 'react';
import API from '../Api';
import { Link } from 'react-router-dom';
import validator from 'validator';

import AlertMessage from '../AlertMessage';

import crypto from 'crypto';
import cookie from 'react-cookies';

const initialState = {
    uemail : '', 
    upassword : '',
    remember : false,
    
    alertclass : '',
    alertmessage : ''
}

class Login extends Component {
    constructor(props) {
        super(props);
        this.state = initialState;

        this.rememberMe = this.rememberMe.bind(this);
        this.checkLogin = this.checkLogin.bind(this);
        this.handleChange = this.handleChange.bind(this);
        this.userSignin = this.userSignin.bind(this);

    }
    componentDidMount(){
        this.checkLogin();
    }
    encrypt(text){
        var cipher = crypto.createCipher('aes-256-cbc','vidzon@#$%^&*()!')
        var crypted = cipher.update(text,'utf8','hex')
        crypted += cipher.final('hex');
        return crypted;
    }
    decrypt(text){
        var decipher = crypto.createDecipher('aes-256-cbc','vidzon@#$%^&*()!')
        var dec = decipher.update(text,'hex','utf8')
        dec += decipher.final('utf8');
        return dec;
    }
    checkLogin(){
        const authtoken = localStorage.getItem('token');
        const rembchk = localStorage.getItem('rememberCheck');
        const loginchk = cookie.load('accesstoken');
        if(authtoken){
            const a = this.decrypt(authtoken);
            const b = a.split('|##|');
            if(b[0] && b[1]){
                this.setState({uemail : b[0] });
                this.setState({upassword : b[1] });
            }
        }
        if(rembchk){
            this.setState({remember : true});
        }
        if(loginchk){
            this.props.history.push("/dashboard");
        }

    }
    handleChange(e){
        this.setState({[e.target.name] : e.target.value });
    }

    rememberMe(e){
        if(this.state.remember === true ){
            console.log(this.state.remember);
            this.setState({ remember : false });            
        }
        if(this.state.remember === false ){
            console.log(this.state.remember);
            this.setState({ remember : true });            
        }
    }

    validateForm(){
        const emptyemail = validator.isEmpty(this.state.uemail, {ignore_whitespace:true});
        const emptypassword = validator.isEmpty(this.state.upassword, {ignore_whitespace:true});
        
        const isemail = validator.isEmail(this.state.uemail);

        const passlength = validator.isLength(this.state.upassword, {min:6, max:15})
        
        if(emptyemail || emptypassword){
            this.setState({ alertclass : 'error', alertmessage : 'Field can not be empty!' });
            return false;
        }
        if(!isemail){
            this.setState({ alertclass : 'error', alertmessage : 'Email Is not valid!' });
            return false;
        }
        if(!passlength){
            this.setState({ alertclass : 'error', alertmessage : 'Password should be between 6 and 15 characters.' });
            return false;
        }
        
        return true;
        
    }

    userSignin(e){
        e.preventDefault();
        const isValid = this.validateForm();
        if(isValid){
            //post signin data to the server 
            API.post('/api/signin', {
                email : this.state.uemail,
                password : this.state.upassword
            })
            .then(res => {
                if(res.data.status === 'success'){

                    let uid = res.data.data.user_id;
                    const uidenc = this.encrypt(''+ uid +'');
                    
                    if(this.state.remember === true){
                        localStorage.setItem('token', res.data.data.token);
                        localStorage.setItem('rememberCheck', true);
                    }
                    localStorage.setItem('uname', res.data.data.user_name);
                    localStorage.setItem('uemail', this.state.uemail);
                    /* this.setState(initialState); */
                    this.setState({ alertclass : 'success', alertmessage : res.data.message });
                    
                    cookie.save('accesstoken', uidenc, { path: '/', maxAge: 86400});
                    localStorage.setItem('accesstoken', uidenc);
                    
                    setTimeout(() => {
                        this.props.history.push("/dashboard");
                    }, 1000);
                }else{
                    this.setState({ alertclass : 'error', alertmessage : res.data.message });
                }
            });

            setTimeout(() => {
                this.setState({alertclass : ''});
                this.setState({alertmessage : ''});
            }, 3000);
        }else{
            setTimeout(() => {
                this.setState({alertclass : ''});
                this.setState({alertmessage : ''});
            }, 3000);
        }

    }

    render() { 
        
        return ( 
            <div className="vd_auth_wrapper">
                <div className="vd_authinner_wrapper">
                    <div className="vd_auth_image">
                        <img src={process.env.PUBLIC_URL + '/assets/images/authimg.png'} alt="Vidzone" />
                    </div>
                    <div className="vd_auth_content">
                        <img src={process.env.PUBLIC_URL + '/assets/images/logo.png'} alt="Vidzone Logo" />
                        <h1 onClick={() => { alert.show('Oh look, an alert!') }}>Sign in</h1>
                        <form onSubmit={this.userSignin}>
                            <div className="vd_authinput_wrapper">
                                <input type="text" className="vd_input" placeholder="Email" name="uemail" value={this.state.uemail}  onChange={this.handleChange} />
                            </div>
                            <div className="vd_authinput_wrapper">
                                <input type="password" className="vd_input" placeholder="Password" name="upassword" value={this.state.upassword} onChange={this.handleChange} />
                            </div>
                            <div className="vd_remember_forgot">
                                <div className="vd_remember_me">
                                    <div className="vd_checkbox">
                                        <input type="checkbox" id="remember_me" name="remeber" className="su_checkbox" value={this.state.remember} defaultChecked={localStorage.getItem('rememberCheck') ? 'checked' : '' }  onChange={this.rememberMe} />
                                        <label htmlFor="remember_me">Remember Me</label>
                                    </div>
                                </div>
                                <div className="vd_forgot_password">
                                    <Link to="/authentication/forgot">Forgot Password ?</Link>
                                </div>
                            </div>
                            <button type="submit" className="vd_btn">Sign in</button>
                            <div className="vd_targetlink">Don't have an account? <Link to="/authentication/signup">Sign up</Link></div>
                        </form>
                    </div>
                </div>

                <AlertMessage alertcls={this.state.alertclass} alertmsg = {this.state.alertmessage} />
            </div>
        );
    }
}
 
export default Login;