import React, { Component } from 'react';
import API from '../Api';
import { Link } from 'react-router-dom';
import validator from 'validator';
import crypto from 'crypto';
import cookie from 'react-cookies';

import AlertMessage from '../AlertMessage';

import Header from './Header';
import Sidebar from './Sidebar';

class SettingAmazon extends Component {
    constructor(props) {
        super(props);
        this.state = { 
            locale : '',
            region : '',
            key : '',
            secret : '',
            tag : '',
            usersettings : [],

            alertclass : '',
            alertmessage : ''
        }

        this.handleChange = this.handleChange.bind(this);
        this.amazonSetting = this.amazonSetting.bind(this);
        this.handleLocaleChange = this.handleLocaleChange.bind(this);
    }
    componentDidMount(){
        let uid = this.decrypt(cookie.load('accesstoken'));
        API.post('api/user_app_settings/' + uid, {
            type : 'amazon',
            user_id : uid
        })
        .then(res => {
            if (res.data.status === 'success') {
                const awsData = res.data.data.awsData;
                let keylength = Object.keys(res.data.data.awsData).length;
                if(keylength !== 0){
                    this.setState({usersettings : awsData});
                    this.setState({
                        locale : awsData.locale,
                        key : awsData.key,
                        secret : awsData.secret,
                        tag : awsData.tag
                    });
                }
            }
            
        });
    }
    decrypt(text){
        var decipher = crypto.createDecipher('aes-256-cbc','vidzon@#$%^&*()!')
        var dec = decipher.update(text,'hex','utf8')
        dec += decipher.final('utf8');
        return dec;
    }
    handleChange(e){
        this.setState({[e.target.name] : e.target.value });
    }
    handleLocaleChange(e){
        let el = document.getElementById('uamz_country');
        let select = el.options[el.selectedIndex];
        if(el){
            this.setState({
                locale : select.value,
                region : select.getAttribute('data-region')
            });
            setTimeout(() => {
                console.log(this.state.locale);
                console.log(this.state.region);
            }, 100);
            
        }
    } 
    validateForm(){
        const emptylocale = validator.isEmpty(this.state.locale, {ignore_whitespace:true});
        const emptykey = validator.isEmpty(this.state.key, {ignore_whitespace:true});
        const emptysecret = validator.isEmpty(this.state.secret, {ignore_whitespace:true});
        const emptytag = validator.isEmpty(this.state.tag, {ignore_whitespace:true});
        
        if(emptylocale || emptykey || emptysecret || emptytag){
            this.setState({ alertclass : 'error', alertmessage : 'Field can not be empty!' });
            return false;
        }
        
        return true;
        
    }
    amazonSetting(e){
        e.preventDefault();
        const isValid = this.validateForm();
        if(isValid){
            document.body.classList.add('open-loader');
            if(this.decrypt(cookie.load('accesstoken'))){
                //post signin data to the server
                API.post('/api/add_aws_detail', {
                    user_id : this.decrypt(cookie.load('accesstoken')),
                    host : this.state.locale,
                    key : this.state.key,
                    secret : this.state.secret,
                    tag : this.state.tag,
                    region : this.state.region
                })
                .then(res => {
                    if(res.data.status === 'success'){
                        this.setState({ alertclass : 'success', alertmessage : res.data.message });
                        this.props.history.push("/settings/amazon");
                    }else{
                        this.setState({ alertclass : 'error', alertmessage : res.data.message });
                    }
                    document.body.classList.remove('open-loader'); 
                    setTimeout(() => {
                        this.setState({alertclass : ''});
                        this.setState({alertmessage : ''});
                    }, 3000);
                });
            }else{
                this.setState({ alertclass : 'error', alertmessage : 'Something went wrong, please Login again.' });
            }
            setTimeout(() => {
                this.setState({alertclass : ''});
                this.setState({alertmessage : ''});
            }, 3000);
        }else{
            setTimeout(() => {
                this.setState({alertclass : ''});
                this.setState({alertmessage : ''});
            }, 3000);
        }

    }
    render() { 
        return ( 
            <div className="vd_main_afterlogin">
                <Header />
                <Sidebar />
                <div className="vd_settingbox_wrapper">
                    <div className="vd_setting_tab_menu">
                        <ul>
                            {/* <li><Link to="/settings/youtube">Youtube</Link></li> */}
                            <li><Link to="/settings/wordpress">WordPress</Link></li>
                            <li className="active"><Link to="/settings/amazon">Amazon</Link></li>
                        </ul>
                    </div>
                    <div className="vd_setting_content">

                        <form onSubmit={this.amazonSetting}>
                            <div className="vd_input_inline">
                                <div className="vd_input_wrapper">
                                    <select id="uamz_country" className="vd_input" value={this.state.locale || ''} onChange={this.handleLocaleChange}>
                                        <option value="">--Select Amazon Locale--</option>
                                        <option data-region="us-west-2" value="webservices.amazon.com.au">Australia (us-west-2)</option>
                                        <option data-region="us-east-1" value="webservices.amazon.com.br">Brazil (us-east-1)</option>
                                        <option data-region="us-east-1" value="webservices.amazon.ca">Canada (us-east-1)</option>
                                        <option data-region="eu-west-1" value="webservices.amazon.fr">France (eu-west-1)</option>
                                        <option data-region="eu-west-1" value="webservices.amazon.de">Germany (eu-west-1)</option>
                                        <option data-region="eu-west-1" value="webservices.amazon.in">India (eu-west-1)</option>
                                        <option data-region="eu-west-1" value="webservices.amazon.it">Italy (eu-west-1)</option>
                                        <option data-region="us-west-2" value="webservices.amazon.co.jp">Japan (us-west-2)</option>
                                        <option data-region="us-east-1" value="webservices.amazon.com.mx">Mexico (us-east-1)</option>
                                        <option data-region="eu-west-1" value="webservices.amazon.nl">Netherlands (eu-west-1)</option>
                                        <option data-region="us-west-2" value="webservices.amazon.sg">Singapore (us-west-2)</option>
                                        <option data-region="eu-west-1" value="webservices.amazon.es">Spain (eu-west-1)</option>
                                        <option data-region="eu-west-1" value="webservices.amazon.com.tr">Turkey (eu-west-1)</option>
                                        <option data-region="eu-west-1" value="webservices.amazon.ae">United Arab Emirates (eu-west-1)</option>
                                        <option data-region="eu-west-1" value="webservices.amazon.co.uk">United Kingdom (eu-west-1)</option>
                                        <option data-region="us-east-1" value="webservices.amazon.com">United States (us-east-1)</option>
                                    </select>
                                </div>
                                <div className="vd_input_wrapper">
                                    <input type="text" className="vd_input" placeholder="Amazon API Key" name="key" value={this.state.key || ''} onChange={this.handleChange} />
                                </div>
                            </div>
                            <div className="vd_input_inline">
                                
                                <div className="vd_input_wrapper">
                                    <input type="text" className="vd_input" placeholder="Amazon Secret Key" name="secret" value={this.state.secret || ''} onChange={this.handleChange} />
                                </div>
                                <div className="vd_input_wrapper">
                                    <input type="text" className="vd_input" placeholder="Associate Tag" name="tag" value={this.state.tag || ''} onChange={this.handleChange} />
                                </div>
                            </div>
                            <br/>
                            <div className="text-center">
                                <button type="submit" className="vd_btn">Save</button> 
                            </div><br/>
                            <p className="text-center"><a href="https://aws.amazon.com/premiumsupport/knowledge-center/create-access-key/" rel="noopener noreferrer" target="_blank">Click here</a> to know how to generate the keys?</p>
                        </form>

                    </div>
                </div>

                <AlertMessage alertcls={this.state.alertclass} alertmsg = {this.state.alertmessage} />
            </div>
        );
    }
}
 
export default SettingAmazon;