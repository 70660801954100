import React, { Component } from 'react';
import { Link , Redirect } from 'react-router-dom';
import cookie from 'react-cookies';

class Sidebar extends Component {
    constructor(props) {
        super(props);
        this.state = { 
            redirect : '',
            isActive: false
        }
        this.logoutUser = this.logoutUser.bind(this);
    }

    logoutUser(){
        localStorage.removeItem('accesstoken');
        cookie.remove('accesstoken', { path: '/' });
        this.setState({redirect: true});
    }
    
    render() { 
        if (this.state.redirect) {
            return <Redirect push to="/" />;
        }
        return ( 
            <div className="vd_sidebar">
                <ul>
                    <li>
                        <Link to="/dashboard"> 
                            <span className="vd_menu_icon"><span className="vd_icons dashboard"></span></span> 
                            Dashboard
                        </Link>
                    </li>
                    <li>
                        <Link to="/my-post"> 
                            <span className="vd_menu_icon"><span className="vd_icons mypost"></span></span> 
                            My Post
                        </Link>
                    </li>
                    <li>
                        <Link to="/settings/wordpress">
                            <span className="vd_menu_icon"><span className="vd_icons settings"></span></span> 
                            Settings
                        </Link>
                    </li>
                    
                </ul>
            </div>
        );
    }
}
 
export default Sidebar;