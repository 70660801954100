import React, { Component } from 'react';
import crypto from 'crypto';
import cookie from 'react-cookies';
import validator from 'validator';

import Header from './Header';
import Sidebar from './Sidebar';
import API from '../Api';

import AlertMessage from '../AlertMessage';

class Profile extends Component {
    constructor(props) {
        super(props);
        this.state = { 
            initials : '',
            uname : '',
            uemail : '',
            upass : '',

            alertclass : '',
            alertmessage : ''
        }

        this.handleChange = this.handleChange.bind(this);
        this.saveProfileFun = this.saveProfileFun.bind(this);
    }
    componentDidMount(){
        let username =  localStorage.getItem('uname');
        let useremail =  localStorage.getItem('uemail');
        if(username || useremail){
            var str = username;
            var matches = str.match(/\b(\w)/g); // ['J','S','O','N']
            var acronym = matches.join(''); // JSON
            var init = acronym.substring(0, 2);
            
            this.setState({uname : username});
            this.setState({initials : init});
            this.setState({uemail : useremail});
        }

    }
    decrypt(text){
        var decipher = crypto.createDecipher('aes-256-cbc','vidzon@#$%^&*()!')
        var dec = decipher.update(text,'hex','utf8')
        dec += decipher.final('utf8');
        return dec;
    }
    validateForm(){
        const emptyname = validator.isEmpty(this.state.uname, {ignore_whitespace:true});
        const emptypass = validator.isEmpty(this.state.upass, {ignore_whitespace:true});
        const passlength = validator.isLength(this.state.upass, {min:6, max: 15})
        if(emptyname){
            this.setState({ alertclass : 'error', alertmessage : 'Field can not be empty!' });
            return false;
        }
        if(this.state.upass){
            if(emptypass){
                this.setState({ alertclass : 'error', alertmessage : 'Password Should not be empty.' });
                return false;    
            }
            if(!passlength){
                this.setState({ alertclass : 'error', alertmessage : 'Password should be between 6 and 15 characters.' });
                return false;
            }
        }
        return true;
    }
    handleChange(e){
        this.setState({ [e.target.name] : e.target.value });
        let usrname =  this.state.uname;
        if(usrname){
            var str = usrname;
            var matches = str.match(/\b(\w)/g); // ['J','S','O','N']
            var acronym = matches.join(''); // JSON
            var init = acronym.substring(0, 2);
            this.setState({initials : init});
        }
    }
    saveProfileFun(e){
        e.preventDefault();
        const isValid = this.validateForm();
        if(isValid){
            const uid = this.decrypt(cookie.load('accesstoken'));
            let data = {
                user_id : uid,
                name : this.state.uname,
                password : this.state.upass
            }
            API.post('api/update_user_profile', data)
            .then(res => {
                console.log(res);
                if(res.data.status === "success"){
                    localStorage.setItem('uname', this.state.uname);
                    this.setState({ alertclass : 'success', alertmessage : res.data.message });
                }else{
                    this.setState({ alertclass : 'error', alertmessage : res.data.message });
                }
            });
        }
        setTimeout(() => {
            this.setState({alertclass : ''});
            this.setState({alertmessage : ''});
        }, 3000);
    }
    render() { 
        return ( 
            <div className="vd_main_afterlogin">
                <Header />
                <Sidebar />

                <div className="vd_settingbox_wrapper">
                    <div className="vd_setting_content">
                        <div className="vd_profile_wrapper">
                            <div className="vd_profile_box">
                                <div className="vd_initials">{this.state.initials}</div>
                                <p>{this.state.uname}</p>
                            </div>
                            <div className="vd_profile_content">
                                <form onSubmit={this.saveProfileFun}>
                                    <h3>Personal Details</h3>
                                    <div className="vd_input_wrapper">
                                        <label>Full Name</label>
                                        <input type="text" className="vd_input" name="uname" value={this.state.uname} onChange={this.handleChange}/>
                                    </div>
                                    <div className="vd_input_wrapper">
                                        <label>Email</label>
                                        <input type="text" className="vd_input" defaultValue={this.state.uemail} readOnly disabled/>
                                    </div>
                                    <h3>Password</h3>
                                    <div className="vd_input_wrapper">
                                        <label>Enter New Password</label>
                                        <input type="password" className="vd_input" name="upass" value={this.state.upass} onChange={this.handleChange}/>
                                    </div>
                                    <div className="vd_note"><strong>Note :</strong> If you'd like to change your password simply enter a new one here (Otherwise leave the field empty).</div><br/>
                                    <button type="submit" className="vd_btn">Update</button>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>

                <AlertMessage alertcls={this.state.alertclass} alertmsg = {this.state.alertmessage} />
            </div>
        );
    }
}
 
export default Profile;