import React, { Component } from 'react';
import crypto from 'crypto';
import cookie from 'react-cookies';
import { Link } from 'react-router-dom';
import { SketchPicker } from 'react-color';
import Draggable from 'react-draggable';

import API from '../Api';

import AlertMessage from '../AlertMessage';

import Header from './Header';
import Sidebar from './Sidebar';

let baseURL = 'https://vidzon.node.pixelnx.com';

const AudioNoData = (props) => {
    let d = props.audiocount;
    if(d){
        return <audio id="vd_audio" style={{ width: '100%', outline : 'none' }} controls src={d ? d + '?v=' + Math.random() : ''}></audio>;
    }else{
        return null;
    }
}

class Editor extends Component {
    constructor(props) {
        super(props);
        this.state = { 
            videourl : '',
            videoname : '',

            edtrText : '',
            edtrColor : '#ffffff',
            edtrTextSize : 20, 
            edtrTextPositionX : 250,
            edtrTextPositionY : 250,

            edtrLogoImg : '',
            edtrLogoPositionX : 20,
            edtrLogoPositionY : 20,

            edtrAudioFile: '',

            videoWidth : '',
            videoHeight : '',

            alertclass : '',
            alertmessage : ''
        }

        this.edtrHandleChange = this.edtrHandleChange.bind(this);
        this.UploadLogoFun = this.UploadLogoFun.bind(this);
        this.UploadAudioFun = this.UploadAudioFun.bind(this);
        this.saveVideoFun = this.saveVideoFun.bind(this);
    }
    componentDidMount(){

        let campid = this.props.match.params.campid;
        const uid = this.decrypt(cookie.load('accesstoken'));
        document.body.classList.add('open-loader');
        if(campid){
            API.get('api/list_campaign/'+ uid +'/'+ campid)
            .then(res => {
                if(res.data.status === 'success'){
                    if(res.data.data.length === 0){
                        //this.props.history.push('/create-campaign');
                    }else{
                        let vstatus = res.data.data[0].video_status;
                        if(vstatus === 'FINISH'){
                            let vidURL = baseURL + '/aws_videos/'+ campid + '/' + res.data.data[0].compile_video_url;
                            this.setState({ videourl : vidURL });
                            this.setState({ videoname : res.data.data[0].compile_video_url });
                        }else{
                            this.props.history.push('/edit-video/' + campid);    
                        }
                        document.body.classList.remove('open-loader');
                    }
                }
            });
            document.body.classList.remove('open-loader');
        }

        /* get wordpress list start */
        API.get('api/list_wpblog/' + uid + '/0')
        .then(res =>{
            if(res.data.status === "success"){
                this.setState({ wpList : res.data.data });
            }
        });
        /* get wordpress list end */
    }
    decrypt(text){
        var decipher = crypto.createDecipher('aes-256-cbc','vidzon@#$%^&*()!')
        var dec = decipher.update(text,'hex','utf8')
        dec += decipher.final('utf8');
        return dec;
    }
    
    edtrHandleChange(e){
        e.preventDefault();
        this.setState({ [e.target.name] : e.target.value });
    }
    
    edtrColorHandleChange = (color) => {
        this.setState({ edtrColor : color.hex });
    }
    UploadLogoFun(e){
        document.body.classList.add('open-loader');
        let uid = this.decrypt(cookie.load('accesstoken'));
        let campid = this.props.match.params.campid;
        let file = e.target.files[0];
        let data = new FormData();
        data.append('file', file);        
        data.append('user_id', uid);
        data.append('camp_id', campid);

        if(file){
            if(file.type.includes('png') === true || file.type.includes('jpg') === true || file.type.includes('jpeg') === true){
                API.post('/api/uploadLogo', data)
                .then(res =>{
                    if(res.data.status === 'success'){
                        let logoURL = baseURL + '/aws_videos/'+ campid + '/logo.png';
                        this.setState({ edtrLogoImg : logoURL });
                        this.setState({ alertclass : 'success', alertmessage : res.data.message });
                        document.body.classList.remove('open-loader');
                    }else{
                        this.setState({ alertclass : 'error', alertmessage : res.data.message });
                    }
                });
            }else{
                document.body.classList.remove('open-loader');
                this.setState({ alertclass : 'error', alertmessage : 'You can upload only Image! (allowed format is png and jpg)' });
            }
        }else{
            document.body.classList.remove('open-loader');
            this.setState({ alertclass : 'error', alertmessage : 'You cancel the upload.' });
        }
        
        setTimeout(() => {
            this.setState({alertclass : ''});
            this.setState({alertmessage : ''});
        }, 3000);
        
    }
    UploadAudioFun(e){
        document.body.classList.add('open-loader');
        let uid = this.decrypt(cookie.load('accesstoken'));
        let campid = this.props.match.params.campid;
        let file = e.target.files[0];
        let data = new FormData();
        data.append('file', file);        
        data.append('user_id', uid);
        data.append('camp_id', campid);
        if(file){
            if(file.type.includes('mp3') === true){
                API.post('/api/uploadAudio', data)
                .then(res =>{
                    if(res.data.status === 'success'){
                        let AudioURL = baseURL + '/aws_videos/'+ campid + '/audio.mp3';
                        this.setState({ edtrAudioFile : AudioURL });
                        this.setState({ alertclass : 'success', alertmessage : res.data.message });
                    }else{
                        this.setState({ alertclass : 'error', alertmessage : res.data.message });
                    }
                    document.body.classList.remove('open-loader');
                });
            }else{
                document.body.classList.remove('open-loader');
                this.setState({ alertclass : 'error', alertmessage : 'You can upload only Music File! (allowed format is MP3)' });
            }
        }else{
            document.body.classList.remove('open-loader');
            this.setState({ alertclass : 'error', alertmessage : 'You cancel the upload.' });
        }
    }

    handleTextDrag = (e, ui) => {
        this.setState({ edtrTextPositionX : ui.x });
        this.setState({ edtrTextPositionY : ui.y });
    }
    handleImgDrag = (e, ui) => {
        this.setState({ edtrLogoPositionX : ui.x });
        this.setState({ edtrLogoPositionY : ui.y });
    }

    saveVideoFun(e){
        document.body.classList.add('open-loader');
        let campid = this.props.match.params.campid;
        let fData = {};
        e.preventDefault();

        let vidw = document.getElementById('vd_video').clientWidth;
        let vidh = document.getElementById('vd_video').clientHeight;
        
        let tx = (this.state.edtrTextPositionX / vidw) * 100;
        let ty = (this.state.edtrTextPositionY / vidh) * 100;
        let tfs = (this.state.edtrTextSize / vidw) * 100;

        let lx = (this.state.edtrLogoPositionX / vidw) * 100;
        let ly = (this.state.edtrLogoPositionY / vidh) * 100;

        let finalTx = ((tx * 1280) / 100).toFixed(0);
        let finalTy = ((ty * 720) / 100).toFixed(0);
        let finalTfs = ((tfs * 1280) / 100).toFixed(0);

        let finalLx = ((lx * 1280) / 100).toFixed(0);
        let finalLy = ((ly * 720) / 100).toFixed(0);

        let videolength = document.getElementById('vd_video').duration;
        let audiolength = 0;
        if(this.state.edtrAudioFile){
            audiolength = document.getElementById('vd_audio').duration;
        }
        
        let editdata = {
            "imageData":{
                "url": this.state.edtrLogoImg,
                "x": finalLx,
                "y": finalLy
            },
            "textData":{
                "text": this.state.edtrText,
                "x": finalTx,
                "y": finalTy,
                "color" : this.state.edtrColor,
                "size" : finalTfs
            },
            "audioData": this.state.edtrAudioFile ? true : false,
            "audiolength" : (audiolength <= videolength) ? true : false
        };
        
        fData.editdata = editdata;
        fData.camp_id = this.props.match.params.campid;
        fData.video_url = this.state.videoname;

        API.post('/api/video_editor', fData)
        .then(res => {
            console.log(res);  
            if(res.data.status === "success"){
                this.setState({ alertclass : 'success', alertmessage : res.data.message });
                setTimeout(() => {
                    this.props.history.push('/edit-video/' + campid);
                }, 3000);
            }else{
                this.setState({ alertclass : 'error', alertmessage : res.data.message });
            }
            document.body.classList.remove('open-loader');
            setTimeout(() => {
                this.setState({alertclass : ''});
                this.setState({alertmessage : ''});
            }, 3000);
        });
    }

    render() { 
        return (             
            <div className="vd_main_afterlogin">
                <Header />
                <Sidebar />
                <div className="vd_campBox_wrapper">
                    <div className="vd_createBlog_wrapper">
                        <div className="vd_createBlog_sidebar">
                            <div className="vd_tab_wrapper">
                                <ul>
                                    <li className="active"><a href="#text_tab" data-toggle="tab">Text</a></li>
                                    <li><a href="#logo_tab" data-toggle="tab">Logo</a></li>
                                    <li><a href="#audio_tab" data-toggle="tab">Audio</a></li>
                                </ul>
                                {/* text tab start */}
                                <div id="text_tab" className="vd_tab_content active">
                                    <div className="vd_input_wrapper">
                                        <label>Enter Text</label>
                                        <textarea rows="5" className="vd_input" name="edtrText" value={this.state.edtrText} onChange={this.edtrHandleChange}></textarea>
                                    </div>
                                    <div className="vd_input_wrapper">
                                        <label>Text Color</label>
                                        <div className="vd_color_picker" ref="colorPickerClass">
                                            <span className="vd_colorPicker_toggle" data-toggle="dropdown" style={{ backgroundColor : this.state.edtrColor }}></span>
                                            <SketchPicker
                                                width= '200'
                                                color={this.state.edtrColor}
                                                onChangeComplete={ this.edtrColorHandleChange }
                                            />
                                        </div>
                                    </div>
                                    <div className="vd_input_wrapper">
                                        <label>Text Size(px)</label>
                                        <input type="number" className="vd_input" name="edtrTextSize" value={this.state.edtrTextSize} onChange={this.edtrHandleChange} />
                                    </div>
                                    
                                    {/* <div className="vd_input_inline">
                                        <div className="vd_input_wrapper">
                                            <label>Position : (x - {this.state.edtrTextPositionX}, y - {this.state.edtrTextPositionY})</label>
                                        </div>
                                    </div> */}
                                </div>
                                {/* text tab end */}

                                {/* logo tab start */}
                                <div id="logo_tab" className="vd_tab_content">
                                    <div className="vd_input_wrapper">
                                        <label>Upload Logo</label>
                                        <div className="vd_upload_image">
                                            <input type="file" className="form-control" onChange={this.UploadLogoFun} accept="image/*" />
                                            <p>Drag Logo to Upload or <span>Browse</span></p>
                                        </div>
                                        <span className="vd_input_info">Logo format should be png,jpg.</span>
                                    </div>
                                    {/* <div className="vd_input_inline">
                                        <div className="vd_input_wrapper">
                                            <label>Position : (x - {this.state.edtrLogoPositionX}, y - {this.state.edtrLogoPositionY})</label>
                                        </div>
                                    </div> */}
                                </div>
                                {/* logo tab end */}

                                {/* audio tab start */}
                                <div id="audio_tab" className="vd_tab_content">
                                    <div className="vd_input_wrapper">
                                        <label>Upload Audio</label>
                                        <div className="vd_upload_image">
                                            <input type="file" className="form-control" onChange={this.UploadAudioFun} accept="audio/mp3" />
                                            <p>Drag Audio to Upload or <span>Browse</span></p>
                                        </div>
                                        <span className="vd_input_info">Audio format should be MP3</span>
                                    </div>
                                    <AudioNoData audiocount={this.state.edtrAudioFile} />
                                    
                                </div>
                                {/* audio tab end */}

                            </div>
                            
                        </div>
                        <div className="vd_createBlog_content">
                            <div className="vd_createBlog_header">
                                <div className="vd_header_left"></div>
                                <div className="vd_header_right">
                                    <button type="button" className="vd_btn vd_btn_green" onClick={this.saveVideoFun}>Save</button>&nbsp;&nbsp;
                                    <Link to={'/edit-video/' + this.props.match.params.campid } className="vd_btn vd_btn_red">Exit Editor</Link>
                                </div>
                            </div>
                            <div className="vd_createBlog_body">
                                <div className="vd_video_wrapper" ref="videoplay">
                                    <video id="vd_video" ref="video" src={this.state.videourl}></video>
                                    <div className="vd_video_overlay_editor">
                                    <Draggable
                                        bounds= "parent"
                                        onDrag={this.handleTextDrag}
                                        defaultPosition={{x: this.state.edtrTextPositionX, y: this.state.edtrTextPositionY}}
                                    >
                                        <p id="vd_edited_text" style={{ color: this.state.edtrColor, fontSize: this.state.edtrTextSize+'px' }} >{this.state.edtrText}</p>
                                    </Draggable>
                                    
                                    <Draggable
                                        bounds= "parent"
                                        onDrag={this.handleImgDrag}
                                        defaultPosition={{x: this.state.edtrLogoPositionX, y: this.state.edtrLogoPositionY}}
                                    >
                                        <div>
                                            <img src={ this.state.edtrLogoImg ? this.state.edtrLogoImg + '?v=' + Math.random() : ''} alt="" />
                                        </div>
                                    </Draggable>
                                        
                                        
                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>
                </div>

                <AlertMessage alertcls={this.state.alertclass} alertmsg = {this.state.alertmessage} />
            </div>
        );
    }
}
 
export default Editor;