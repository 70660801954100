import React, { Component } from 'react';
import { Link , Redirect } from 'react-router-dom';
import cookie from 'react-cookies';

class Header extends Component {
    constructor(props) {
        super(props);
        this.state = { 
            redirect : '',
            initials : '',
            uname : ''
        }
        this.logoutUser = this.logoutUser.bind(this);
    }
    componentDidMount(){
        let username = localStorage.getItem('uname');
        if(username){
            var str = username;
            var matches = str.match(/\b(\w)/g); // ['J','S','O','N']
            var acronym = matches.join(''); // JSON
            var init = acronym.substring(0, 2);
            
            this.setState({uname : username});
            this.setState({initials : init});
        }
        
    }
    logoutUser(){
        localStorage.removeItem('accesstoken');
        cookie.remove('accesstoken', { path: '/' });
        this.setState({redirect: true});
    }
    render() { 
        if (this.state.redirect) {
            return <Redirect push to="/" />;
        }
        return ( 
            <div className="vd_header_wrapper">
                <div className="vd_sidebar_toggle">
                    <div className="vd_sidebar_toggle_icon">
                        <span></span>
                        <span></span>
                        <span></span>
                    </div>
                </div>
                <div className="vd_header_logo">
                    <img src={process.env.PUBLIC_URL + '/assets/images/logo.png'} alt="Vidzone Logo" />
                </div>
                <div className="vd_header_right">
                    <div className="vd_header_profile vd_dropdown">
                        <div className="vd_header_profile_icon" data-toggle="dropdown">
                            <span className="vd_initials">{this.state.initials}</span>
                            <span className="vd_name">{this.state.uname}</span>
                            <span className="vd_dots">
                                <span></span><span></span><span></span>
                            </span>
                        </div>
                        <div className="vd_dropdown_wrapper vd_dropdown_right">
                            <div className="vd_dropdown_inner">
                                <ul>
                                    <li><Link to="/profile">Profile</Link></li>
                                    <li><span onClick={this.logoutUser}>Logout</span></li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}
 
export default Header;