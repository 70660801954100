import React, { Component } from 'react';
import { Switch, Route , Redirect } from 'react-router-dom';
import cookie from 'react-cookies';

import Login from './auth/Login';
import Signup from './auth/Signup';
import Forgot from './auth/Forgot';
import Reset from './auth/Reset';

import Dashboard from './contents/Dashboard';

/* import SettingYoutube from './contents/SettingYoutube'; */
import SettingWordpress from './contents/SettingWordpress';
import SettingAmazon from './contents/SettingAmazon';

import CreateCampaign from '../components/contents/CreateCampaign/CreateCampaign';
import GetVideo from '../components/contents/CreateCampaign/GetVideo';
import EditVideo from '../components/contents/CreateCampaign/EditVideo';
import CreateBlog from '../components/contents/CreateCampaign/CreateBlog';
import PublishOnYouTube from '../components/contents/CreateCampaign/PublishOnYouTube';

import Editor from './contents/Editor';
import Profile from './contents/Profile';
import MyPost from './contents/MyPost';

import Page404 from './Page404';

const PrivateRoute = ({ component : Component, ...rest }) => (
    <Route
        { ...rest }
        render = { props =>
            cookie.load('accesstoken') ? (
                <Component {...props} />
            ) : (
                <Redirect to={{
                    pathname: "/",
                    state: { from : props.location }
                }}
                />
            )
        }
    />
);

class Main extends Component {
    constructor(props) {
        super(props);
        this.state = {}

        /* check if user change anything in accesstoken start */
        const a = cookie.load('accesstoken');
        const b = localStorage.getItem('accesstoken');
        if(a === b || a === undefined && b === null){
            
        }else{
            localStorage.removeItem('accesstoken');
            cookie.remove('accesstoken', { path: '/' });
        }
        /* check if user change anything in accesstoken end */

    }
    render() { 
        return ( 
            <Switch>
                <Route exact path="/" component={Login} />
                <Route path="/authentication/login" component={Login} />
                <Route path="/authentication/signup" component={Signup} />
                <Route path="/authentication/forgot" component={Forgot} />
                <Route path="/authentication/reset/:code" component={Reset} />
            
                <PrivateRoute exact path="/dashboard" component={Dashboard} />
                {/* <PrivateRoute exact path="/settings/youtube" component={SettingYoutube} /> */}
                <PrivateRoute exact path="/settings/wordpress" component={SettingWordpress} />
                <PrivateRoute exact path="/settings/amazon" component={SettingAmazon} />

                <PrivateRoute exact path="/create-campaign" component={CreateCampaign} />
                <PrivateRoute exact path="/create-campaign/:campid" component={CreateCampaign} />
                <PrivateRoute exact path="/get-video" component={GetVideo} />
                <PrivateRoute exact path="/get-video/:campid" component={GetVideo} />
                <PrivateRoute exact path="/edit-video" component={EditVideo} />
                <PrivateRoute exact path="/edit-video/:campid" component={EditVideo} />
                <PrivateRoute exact path="/create-blog" component={CreateBlog} />
                <PrivateRoute exact path="/create-blog/:campid" component={CreateBlog} />
                <PrivateRoute exact path="/publish-on-youtube" component={PublishOnYouTube} />
                <PrivateRoute exact path="/publish-on-youtube/:campid" component={PublishOnYouTube} />
                <PrivateRoute exact path="/editor" component={Editor} />
                <PrivateRoute exact path="/editor/:campid" component={Editor} />
                <PrivateRoute exact path="/profile" component={Profile} />
                <PrivateRoute exact path="/my-post" component={MyPost} />

                <Route component={Page404}/>
            </Switch>
        ); 
    }
}
 
export default Main;