import React, { Component } from 'react';
import API from '../Api';
import { Link } from 'react-router-dom';
import validator from 'validator';

import AlertMessage from '../AlertMessage';

const initialState = {
    uname : '',
    uemail : '', 
    upassword : '',
    checked : false,

    alertclass : '',
    alertmessage : ''
}

class Signup extends Component {
    constructor(props) {
        super(props);
        this.state = initialState;

        this.handleChange = this.handleChange.bind(this);
        this.userSignup = this.userSignup.bind(this);
        this.termAccept = this.termAccept.bind(this);
    }

    handleChange(e){
        this.setState({[e.target.name] : e.target.value });
    }
    termAccept(e){
        if(this.state.checked === false ){
            this.setState({ checked : true });
        }
        if(this.state.checked === true ){
            this.setState({ checked : false });
        }
    }
    validateForm(){
        const emptyname = validator.isEmpty(this.state.uname, {ignore_whitespace:true});
        const emptyemail = validator.isEmpty(this.state.uemail, {ignore_whitespace:true});
        const emptypassword = validator.isEmpty(this.state.upassword, {ignore_whitespace:true});
        
        const isemail = validator.isEmail(this.state.uemail);

        const passlength = validator.isLength(this.state.upassword, {min:6})
        
        if(emptyname || emptyemail || emptypassword){
            this.setState({ alertclass : 'error', alertmessage : 'Field can not be empty!' });
            return false;
        }
        if(!isemail){
            this.setState({ alertclass : 'error', alertmessage : 'Email Is not valid!' });
            return false;
        }
        if(!passlength){
            this.setState({ alertclass : 'error', alertmessage : 'password should be minimum 6 characters.' });
            return false;
        }
        
        if(!this.state.checked === true){
            this.setState({ alertclass : 'error', alertmessage : 'Please accept the Terms & Conditions.' });
            return false;
        }
        
        return true;
        
    }

    userSignup(e){
        e.preventDefault();

        const isValid = this.validateForm();
        if(isValid){
            if(this.state.checked){
                //post signup data to the server 
                API.post('/api/signup', {
                    name : this.state.uname,
                    email : this.state.uemail,
                    password : this.state.upassword
                })
                .then(res => {
                    if(res.data.status === 'success'){
                        this.setState(initialState);
                        this.setState({ alertclass : 'success', alertmessage : res.data.message });
                        setTimeout(() => {
                            this.props.history.push("/");
                        }, 2000);
                    }else{
                        this.setState({ alertclass : 'error', alertmessage : res.data.message });
                    }
                });
            }           

            setTimeout(() => {
                this.setState({alertclass : ''});
                this.setState({alertmessage : ''});
            }, 3000);
        }else{
            setTimeout(() => {
                this.setState({alertclass : ''});
                this.setState({alertmessage : ''});
            }, 3000);
        }

    }
    render() { 
        return ( 
            <div className="vd_auth_wrapper">
                <div className="vd_authinner_wrapper">
                    <div className="vd_auth_image">
                        <img src={process.env.PUBLIC_URL + '/assets/images/authimg.png'} alt="Vidzone" />
                    </div>
                    <div className="vd_auth_content">
                        <img src={process.env.PUBLIC_URL + '/assets/images/logo.png'} alt="Vidzone Logo" />
                        <h1>Sign up</h1>

                        <form onSubmit={this.userSignup}>
                            <div className="vd_authinput_wrapper">
                                <input type="text" className="vd_input" placeholder="Name" name="uname" value={this.state.uname}  onChange={this.handleChange}/>
                            </div>
                            <div className="vd_authinput_wrapper">
                                <input type="text" className="vd_input" placeholder="Email" name="uemail" value={this.state.uemail}  onChange={this.handleChange} />
                            </div>
                            <div className="vd_authinput_wrapper">
                                <input type="password" className="vd_input" placeholder="Password" name="upassword" value={this.state.upassword} onChange={this.handleChange} />
                            </div>
                            <div className="vd_remember_forgot">
                                <div className="vd_remember_me">
                                    <div className="vd_checkbox">
                                        <input type="checkbox" id="agree_terms" name="checked" value={this.state.checked} onChange={this.termAccept} />
                                        <label htmlFor="agree_terms">I Agree To The <Link to="/tnc">Vidzone Terms & Conditions</Link></label>
                                    </div>
                                </div>
                            </div>
                            <button type="submit" className="vd_btn">Sign up</button>
                            <div className="vd_targetlink">Already have an account? <Link to="/">Sign in</Link></div>
                        </form>

                    </div>
                </div>

                <AlertMessage alertcls={this.state.alertclass} alertmsg = {this.state.alertmessage} />

            </div>
        );
    }
}
 
export default Signup;