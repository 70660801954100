import React, { Component } from 'react';

class AlertMessage extends Component {
    constructor(props) {
        super(props);
        this.state = { 
            errorClass : '',
            successClass : '',
            errorMessage : '',
            successMessage : ''
        }
    }
    componentDidMount(){
        /* setTimeout(
            function() {
                this.setState({position: 1});
                
            }
            .bind(this),
            3000
        ); */
    }
    render() { 
        if(this.props.alertmsg === ''){
            return false;
        }else{
            return ( 
                <div className="vd_alert_wrapper">
                    <p className={'vd_alert ' + this.props.alertcls}>{this.props.alertmsg}</p>
                </div>
            );
        }
        
    }
}
 
export default AlertMessage;