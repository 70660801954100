import React, { Component } from 'react';
import API from '../Api';
import { Link } from 'react-router-dom';
import crypto from 'crypto';
import cookie from 'react-cookies';

import AlertMessage from '../AlertMessage';

import Header from './Header';
import Sidebar from './Sidebar';

let baseURL = 'https://vidzon.node.pixelnx.com';


const CampaignListNoData = (props) => {
    let d = props.campcount;
    if(d === 0){
        return <div className="campaign_nodata"><img src={process.env.PUBLIC_URL + 'assets/images/create_camp_icon.png'} alt="" /><p>There is no campaign created yet!</p></div>;
    }else{
        return null;
    }
}

class Dashboard extends Component {
    constructor(props) {
        super(props);
        this.state = { 
            campaigns : [],

            alertclass : '',
            alertmessage : ''
        }
        this.deleteCampaign = this.deleteCampaign.bind(this);
    }
    componentDidMount(){
        document.body.classList.add('open-loader');
        let uid = this.decrypt(cookie.load('accesstoken'));
        API.get('api/list_campaign/' + uid + '/0')
        .then(res => {
            if(res.data.status === 'success'){
                this.setState({ campaigns : res.data.data });
            }else{
                this.setState({ alertclass : 'error', alertmessage : 'Something went wrong, please Login again.' });
            }
            document.body.classList.remove('open-loader');
        })
    }
    decrypt(text){
        var decipher = crypto.createDecipher('aes-256-cbc','vidzon@#$%^&*()!')
        var dec = decipher.update(text,'hex','utf8')
        dec += decipher.final('utf8');
        return dec;
    }
    deleteCampaign(campid , e){
        e.preventDefault();
        const r = window.confirm("Do you really want to Delete?"); 
        if(r === true){
            let uid = this.decrypt(cookie.load('accesstoken'));
            API.get('api/delete_campaign/'+ uid +'/' + campid)
            .then(res => {
                if(res.data.status === 'success'){
                    let findItem = this.state.campaigns.find(item => item.id === campid);
                    let index = this.state.campaigns.indexOf(findItem);
                    if (index > -1) {
                        this.state.campaigns.splice(index, 1);
                    }
                    this.setState(this.state.campaigns);
                    this.setState({ alertclass : 'success', alertmessage : res.data.message });                    
                }else{
                    this.setState({ alertclass : 'error', alertmessage : res.data.message });
                }
                
            });
        }
        setTimeout(() => {
            this.setState({alertclass : ''});
            this.setState({alertmessage : ''});
        }, 3000);
    }
    render() { 
        return ( 
            <div className="vd_main_afterlogin">
                <Header />
                <Sidebar />
                <div className="vd_content_wrapper">
                    <div className="text-right">
                        <Link to="/create-campaign" className="vd_btn">Create Campaign</Link>
                    </div><br/><br/>
                    <CampaignListNoData campcount={this.state.campaigns.length} /> 
                    <div className="vd_campaign_list">
                        {this.state.campaigns.map((camp , index) =>
                            <div key={camp.id} className="vd_campaign" style={{ animationDelay : `${index * 50}ms` }}>
                                <div className="vd_campimg">
                                    <img src={ camp.video_thumbnail === null ? process.env.PUBLIC_URL + '/assets/images/no_thumb.svg' : baseURL + '/aws_videos/'+ camp.id + '/video_thumbnail.jpg?v=' + Math.random()} alt="" />
                                    <Link to={ camp.id ? '/create-campaign/' + camp.id : '/create-campaign' } className="vd_camp_overlay">
                                        <span className="vd_camp_play_btn">
                                            <img src={ process.env.PUBLIC_URL + '/assets/images/icons/play.svg' } alt="" />
                                        </span>
                                        <span className="vd_campVid_delete" title="Delete" onClick={ (e) => this.deleteCampaign((camp.id), e)}></span>
                                    </Link>
                                </div>
                                <h3>{camp.name}</h3>
                            </div>
                        )}

                    </div>
                </div>

                <AlertMessage alertcls={this.state.alertclass} alertmsg = {this.state.alertmessage} />
            </div>            
        );
    }
}

export default Dashboard;